export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type ApiKeyInput = {
  apiKey: Scalars['String'];
  read: Scalars['Boolean'];
  withdraw: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type Account = {
  __typename?: 'Account';
  available: Scalars['String'];
  balance: Scalars['String'];
  currency: Symbol;
  currencyName: Scalars['String'];
  fiatValue: AccountFiatValue;
  /** @deprecated use fiatValue and and specify fiatMarket in param of query! */
  fiatValues: Array<AccountFiatValues>;
  hold: Scalars['String'];
  id: Scalars['ID'];
};

export type AccountEntity = {
  __typename?: 'AccountEntity';
  accountGroup?: Maybe<AccountGroup>;
  accountGroups: Array<AccountGroup>;
  availableCryptoCurrencies?: Maybe<Array<CryptoCurrencyPayload>>;
  cryptoCurrencies?: Maybe<Array<CryptoCurrencyPayload>>;
};

export type AccountEntityAccountGroupArgs = {
  id: Scalars['ID'];
};

export type AccountEntityAvailableCryptoCurrenciesArgs = {
  accountGroupId?: InputMaybe<Scalars['String']>;
};

export type AccountFiatValue = {
  __typename?: 'AccountFiatValue';
  available: Scalars['String'];
  balance: Scalars['String'];
  currency: FiatCurrency;
  hold: Scalars['String'];
};

export type AccountFiatValues = {
  __typename?: 'AccountFiatValues';
  available: Scalars['String'];
  balance: Scalars['String'];
  currency: Scalars['String'];
  hold: Scalars['String'];
};

export type AccountGroup = {
  accounts?: Maybe<Array<Account>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  totalFiatValue: AccountTotalFiatValue;
  type: AccountType;
  uuid: Scalars['String'];
};

export type AccountTotalFiatValue = {
  __typename?: 'AccountTotalFiatValue';
  currency: FiatCurrency;
  value: Scalars['String'];
  valueWithFiat: Scalars['String'];
};

export enum AccountType {
  Current = 'current',
  HighSavings = 'highSavings',
  Savings = 'savings',
  Staking = 'staking',
}

export type ActivateStakingInput = {
  accountGroupId?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  currency: CryptoCurrency;
};

export type Activity = {
  __typename?: 'Activity';
  created: Scalars['DateTime'];
  details: ActivityDetails;
  id: Scalars['ID'];
  type: ActivityType;
};

export type ActivityDetails = {
  __typename?: 'ActivityDetails';
  accountNumber?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  aon?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  detailsId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ActivityType {
  AddBankAccount = 'AddBankAccount',
  BankCredit = 'BankCredit',
  CancelOrder = 'CancelOrder',
  ChangePassword = 'ChangePassword',
  ConvertBid = 'ConvertBid',
  CreateApiKey = 'CreateApiKey',
  CreateOrder = 'CreateOrder',
  CreateVoucher = 'CreateVoucher',
  Created = 'Created',
  Credit = 'Credit',
  EnableTwoFactor = 'EnableTwoFactor',
  FillOrder = 'FillOrder',
  IdentitySet = 'IdentitySet',
  KycCompleted = 'KycCompleted',
  ReceiveFromUser = 'ReceiveFromUser',
  RemoveApiKey = 'RemoveApiKey',
  RemoveTwoFactor = 'RemoveTwoFactor',
  RequestEmailVerification = 'RequestEmailVerification',
  SendToUser = 'SendToUser',
  UpdateUser = 'UpdateUser',
  VerifyBankAccount = 'VerifyBankAccount',
  Withdraw = 'Withdraw',
  WithdrawComplete = 'WithdrawComplete',
}

export type AdTractionInput = {
  clickId: Scalars['String'];
  client: Scalars['String'];
  country: Scalars['String'];
  orderId: Scalars['String'];
};

export type AddContactInput = {
  address: Scalars['String'];
  currency: CryptoCurrency;
  name: Scalars['String'];
  tag?: InputMaybe<Scalars['String']>;
};

export type AddressValidity = {
  __typename?: 'AddressValidity';
  error?: Maybe<AddressValidityError>;
  valid?: Maybe<Scalars['Boolean']>;
};

export enum AddressValidityError {
  Invalid = 'invalid',
  SameOrigin = 'sameOrigin',
}

export type AdyenLimits = {
  __typename?: 'AdyenLimits';
  fee: Scalars['Float'];
  maxMonthlyDeposit: Scalars['Float'];
  maxSingleDeposit: Scalars['Float'];
  totalMonthlyDeposited: Scalars['Float'];
};

export type AdyenPaymentMethods = {
  __typename?: 'AdyenPaymentMethods';
  id: Scalars['ID'];
  type: AdyenPaymentType;
};

export enum AdyenPaymentStatus {
  Authorised = 'Authorised',
  Cancelled = 'Cancelled',
  Error = 'Error',
  Pending = 'Pending',
  Received = 'Received',
  RedirectShopper = 'RedirectShopper',
  Refused = 'Refused',
}

export enum AdyenPaymentType {
  Applepay = 'applepay',
  Googlepay = 'googlepay',
  Mobilepay = 'mobilepay',
  Paywithgoogle = 'paywithgoogle',
  Scheme = 'scheme',
  Vipps = 'vipps',
}

export enum AdyenPlatform {
  Android = 'android',
  Ios = 'ios',
  Web = 'web',
}

export type AdyenSubmitPaymentDetailsInput = {
  redirectResult: Scalars['String'];
};

export type AdyenSubmitPaymentDetailsPayload = {
  __typename?: 'AdyenSubmitPaymentDetailsPayload';
  resultCode?: Maybe<AdyenPaymentStatus>;
  success: Scalars['Boolean'];
};

export type AdyenSubmitPaymentInput = {
  amount: Scalars['String'];
  currency: FiatCurrency;
  encryptedCardNumber?: InputMaybe<Scalars['String']>;
  encryptedExpiryMonth?: InputMaybe<Scalars['String']>;
  encryptedExpiryYear?: InputMaybe<Scalars['String']>;
  encryptedSecurityCode?: InputMaybe<Scalars['String']>;
  feeCalculation: FeeCalculationType;
  order?: InputMaybe<PaymentMarketOrderInput>;
  platform: AdyenPlatform;
  redirectUrl: Scalars['String'];
  type: AdyenPaymentType;
};

export type AdyenSubmitPaymentPayload = {
  __typename?: 'AdyenSubmitPaymentPayload';
  message?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  resultCode?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type Affiliate = {
  __typename?: 'Affiliate';
  referral?: Maybe<AffiliateReferral>;
  referralCodes?: Maybe<Array<Scalars['String']>>;
  referrals?: Maybe<Array<AffiliateReferral>>;
  rewards?: Maybe<Array<AffiliateReward>>;
};

export type AffiliateInfo = {
  __typename?: 'AffiliateInfo';
  bonusPerReferral: Scalars['String'];
  commisionPerReferral: Scalars['String'];
  welcomeBonus: Scalars['String'];
};

export type AffiliateOwnerPayload = {
  __typename?: 'AffiliateOwnerPayload';
  name?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
  welcomeBonus?: Maybe<Scalars['String']>;
  welcomeBonusCurrency?: Maybe<Scalars['String']>;
};

export type AffiliateReferral = {
  __typename?: 'AffiliateReferral';
  code?: Maybe<Scalars['String']>;
  count?: Maybe<ReferralCountTotal>;
  payout?: Maybe<Array<Maybe<Payout>>>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
};

export type AffiliateReward = {
  __typename?: 'AffiliateReward';
  amount?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['String']>;
  currency?: Maybe<Symbol>;
  description?: Maybe<Scalars['String']>;
  fiatCurrency?: Maybe<FiatCurrency>;
  fiatValue?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  metric?: Maybe<Scalars['String']>;
  metricGoal?: Maybe<Scalars['String']>;
  metricName?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  transactionType?: Maybe<AffiliateTransaction>;
  type?: Maybe<RewardTypes>;
  updatedAt?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
  uuid: Scalars['ID'];
};

export type AffiliateRewards = {
  __typename?: 'AffiliateRewards';
  completed: Scalars['Boolean'];
  description: Scalars['String'];
  priceCurrency: Symbol;
  prize: Scalars['String'];
  progress: Scalars['String'];
  type: RewardTypes;
};

export type AffiliateStat = {
  __typename?: 'AffiliateStat';
  currency: Symbol;
  last: Scalars['String'];
  pending: Scalars['String'];
  total: Scalars['String'];
  type: AffiliateTransaction;
};

export type AffiliateStats = {
  __typename?: 'AffiliateStats';
  bonus: Array<AffiliateStat>;
  commission: Array<AffiliateStat>;
  feebackBonus: Array<AffiliateStat>;
};

export enum AffiliateTransaction {
  AffiliateBonus = 'AffiliateBonus',
  AffiliateCommision = 'AffiliateCommision',
  Bonus = 'Bonus',
  ChristmasBonus = 'ChristmasBonus',
  FeebackBonus = 'FeebackBonus',
  NewWelcome = 'NewWelcome',
  WelcomeBonus = 'WelcomeBonus',
}

export type Airdrop = {
  __typename?: 'Airdrop';
  address?: Maybe<AirdropAddress>;
  flare?: Maybe<Flare>;
};

export type AirdropAddressArgs = {
  currency: Scalars['String'];
};

export type AirdropAddress = {
  __typename?: 'AirdropAddress';
  address?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
};

export type AirdropAddressInput = {
  address: Scalars['String'];
  currency: Scalars['String'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  apiKey: Scalars['String'];
  read: Scalars['Boolean'];
  withdraw: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type AppVersion = {
  __typename?: 'AppVersion';
  version?: Maybe<Scalars['String']>;
  versions?: Maybe<Versions>;
};

export type AuthenticateUserPayload = {
  __typename?: 'AuthenticateUserPayload';
  sessionId: Scalars['String'];
};

export type Balance = {
  __typename?: 'Balance';
  available?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  fiatValue: BalanceFiatValue;
  hold?: Maybe<Scalars['String']>;
  /** @deprecated Will be replaced by values! */
  value: CurrencyValue;
  /** @deprecated Will be replaced by fiatValue! */
  values: CurrencyValues;
};

export type BalanceFiatValue = {
  __typename?: 'BalanceFiatValue';
  available?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  currency: FiatCurrency;
  hold?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountNumber?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  routingNumber?: Maybe<Scalars['String']>;
  swiftbic?: Maybe<Scalars['String']>;
};

export type BankAccountInput = {
  accountNumber: Scalars['String'];
  displayName: Scalars['String'];
  iban?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  swiftbic?: InputMaybe<Scalars['String']>;
};

export enum BankIdCompleteStatus {
  Aborted = 'aborted',
  Error = 'error',
  Success = 'success',
}

export enum BankIdFlow {
  AppToApp = 'appToApp',
  Redirect = 'redirect',
}

export type BankIdIframeInput = {
  sessionId: Scalars['String'];
  state: Scalars['String'];
};

export type BankIdInput = {
  gdpr: GdprType;
  redirectType: BankIdRedirectType;
};

export enum BankIdPlatform {
  Android = 'android',
  Ios = 'ios',
  Web = 'web',
}

export enum BankIdRedirectType {
  Mobile = 'mobile',
  Web = 'web',
}

export type BankIdSessionPayload = {
  __typename?: 'BankIdSessionPayload';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export enum BankIdSessionType {
  Giftcard = 'giftcard',
  Test = 'test',
  Verification = 'verification',
  WithdrawCrypto = 'withdrawCrypto',
  WithdrawFiat = 'withdrawFiat',
}

export type BankIdStatus = {
  requestid: Scalars['String'];
  state: Scalars['String'];
};

export type BankIdUrl = {
  __typename?: 'BankIdUrl';
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type BankOpenBanking = {
  __typename?: 'BankOpenBanking';
  bankOfficialName?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  providers: Array<OpenBankingProvider>;
};

export type Banner = {
  __typename?: 'Banner';
  area?: Maybe<BannerArea>;
  currency?: Maybe<Symbol>;
  da?: Maybe<BannerContent>;
  en?: Maybe<BannerContent>;
  id: Scalars['ID'];
  irremovable?: Maybe<Scalars['Boolean']>;
  no?: Maybe<BannerContent>;
  type?: Maybe<BannerType>;
  uuid?: Maybe<Scalars['String']>;
};

export enum BannerArea {
  AdaDeposit = 'adaDeposit',
  BtcDeposit = 'btcDeposit',
  BuySell = 'buySell',
  DaiDeposit = 'daiDeposit',
  Dashboard = 'dashboard',
  EthDeposit = 'ethDeposit',
  Global = 'global',
  Holdings = 'holdings',
  Invest = 'invest',
  Login = 'login',
  LtcDeposit = 'ltcDeposit',
  Settings = 'settings',
  Transfers = 'transfers',
  XrpDeposit = 'xrpDeposit',
}

export type BannerContent = {
  __typename?: 'BannerContent';
  content?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum BannerType {
  Default = 'default',
  Error = 'error',
  KycMandatory = 'kyc_mandatory',
  Warning = 'warning',
}

export type BannersInput = {
  currency?: InputMaybe<Symbol>;
};

export type Bar = {
  __typename?: 'Bar';
  close?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  high?: Maybe<Scalars['String']>;
  low?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
};

export type CalculatePaymentFeeInput = {
  amount: Scalars['String'];
  currency: FiatCurrency;
  feeCalculation: FeeCalculationType;
  type: PaymentMethodType;
};

export type CalculatePaymentFeePayload = {
  __typename?: 'CalculatePaymentFeePayload';
  amount?: Maybe<Scalars['String']>;
  currency?: Maybe<FiatCurrency>;
  feeAmount?: Maybe<Scalars['String']>;
  feePercent?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  totalAmount?: Maybe<Scalars['String']>;
};

export type Calendar = {
  __typename?: 'Calendar';
  question?: Maybe<CalendarQuestion>;
  questions?: Maybe<Array<CalendarQuestion>>;
};

export type CalendarQuestionArgs = {
  day: Scalars['Int'];
};

export type CalendarAnswer = {
  __typename?: 'CalendarAnswer';
  correct?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  text: CalendarText;
};

export type CalendarQuestion = {
  __typename?: 'CalendarQuestion';
  answers: Array<Maybe<CalendarAnswer>>;
  day: Scalars['Int'];
  id: Scalars['ID'];
  prize: CalendarQuestionPrize;
  question: CalendarText;
};

export type CalendarQuestionPrize = {
  __typename?: 'CalendarQuestionPrize';
  amount: Scalars['Int'];
  currency: CryptoCurrency;
  fiatCurrency: FiatCurrency;
};

export type CalendarQuestionPrizeFiat = {
  __typename?: 'CalendarQuestionPrizeFiat';
  amount: Scalars['Float'];
  currency: FiatCurrency;
};

export type CalendarText = {
  __typename?: 'CalendarText';
  da: Scalars['String'];
  en: Scalars['String'];
  no: Scalars['String'];
};

export type CancelOrderInput = {
  orderId: Scalars['String'];
};

export type CancelWithdrawInput = {
  id: Scalars['ID'];
};

export type CancelWithdrawPayload = {
  __typename?: 'CancelWithdrawPayload';
  /** @deprecated use WithdrawV2 */
  withdraw?: Maybe<Withdraw>;
  withdrawV2?: Maybe<WithdrawV2>;
};

export type ChangePasswordInput = {
  key: Scalars['String'];
  otp?: InputMaybe<Scalars['String']>;
};

export type ClaimRewardPayload = {
  __typename?: 'ClaimRewardPayload';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  uuid: Scalars['ID'];
};

export type ConsentInput = {
  newsletter?: InputMaybe<Scalars['Boolean']>;
  profile: Scalars['Boolean'];
  v3: Scalars['Boolean'];
  v4: Scalars['Boolean'];
};

export type Contact = {
  __typename?: 'Contact';
  address: Scalars['String'];
  currency: CryptoCurrency;
  name: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
};

export type Corporate = {
  __typename?: 'Corporate';
  name?: Maybe<Scalars['String']>;
};

export type CreateAccountGroup = {
  consent?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type: AccountType;
};

export type CreateAccountGroupPayload = {
  __typename?: 'CreateAccountGroupPayload';
  accountGroupId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateAccountInput = {
  affiliate?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  key: Scalars['String'];
  newsletter?: InputMaybe<Scalars['Boolean']>;
  uuid: Scalars['String'];
};

export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  msg?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateLightningInvoiceInput = {
  amountSat: Scalars['Int'];
};

export type CreateMarketOrderInput = {
  amount: Scalars['String'];
  baseCurrency: CryptoCurrency;
  fromAg: Scalars['String'];
  price: Scalars['String'];
  priceSlippage?: InputMaybe<Scalars['Float']>;
  quoteCurrency: FiatCurrency;
  slippage?: InputMaybe<Scalars['Int']>;
  type: OrderType;
};

export type CreateMarketOrderPayload = {
  __typename?: 'CreateMarketOrderPayload';
  message?: Maybe<Scalars['String']>;
  result?: Maybe<MarketOrderPayload>;
  success: Scalars['Boolean'];
};

export type CreateSavingAgreement = {
  accountGroupId: Scalars['String'];
  amount?: InputMaybe<Scalars['Float']>;
  recurring?: InputMaybe<Scalars['Int']>;
};

export type CreateVoucherBankIdInput = {
  amount: Scalars['String'];
  currency: CryptoCurrency;
  sessionId: Scalars['String'];
  sessionType: BankIdSessionType;
  status: BankIdCompleteStatus;
  to: Scalars['String'];
  voucherType: VoucherType;
};

export type CreateVoucherBankIdPayload = {
  __typename?: 'CreateVoucherBankIdPayload';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum CryptoCurrency {
  Aave = 'AAVE',
  Ada = 'ADA',
  Avax = 'AVAX',
  Bnb = 'BNB',
  Btc = 'BTC',
  Dai = 'DAI',
  Doge = 'DOGE',
  Dot = 'DOT',
  Eth = 'ETH',
  Link = 'LINK',
  Ltc = 'LTC',
  Mana = 'MANA',
  Matic = 'MATIC',
  Pol = 'POL',
  Sand = 'SAND',
  Sol = 'SOL',
  Uni = 'UNI',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Xlm = 'XLM',
  Xrp = 'XRP',
}

export type CryptoCurrencyPayload = {
  __typename?: 'CryptoCurrencyPayload';
  hasTag?: Maybe<Scalars['Boolean']>;
  id: CryptoCurrency;
  name: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  /** @deprecated Use account query instead because this only returns main account */
  balance?: Maybe<Balance>;
  enabled?: Maybe<CurrencyEnabled>;
  fiat: Scalars['Boolean'];
  hasTag?: Maybe<Scalars['Boolean']>;
  has_tag?: Maybe<Scalars['Boolean']>;
  id: Symbol;
  name: Scalars['String'];
  symbol: Symbol;
  walletAddress_v4?: Maybe<WalletPayload>;
  withdrawLimit: WithdrawLimit;
};

export type CurrencyWalletAddress_V4Args = {
  skip?: InputMaybe<Scalars['Boolean']>;
};

export type CurrencyEnabled = {
  __typename?: 'CurrencyEnabled';
  deposit?: Maybe<Scalars['Boolean']>;
  withdraw?: Maybe<Scalars['Boolean']>;
};

export type CurrencyValue = {
  __typename?: 'CurrencyValue';
  NOK: Scalars['String'];
};

export type CurrencyValueEntity = {
  __typename?: 'CurrencyValueEntity';
  available?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  hold?: Maybe<Scalars['String']>;
};

export type CurrencyValues = {
  __typename?: 'CurrencyValues';
  NOK: CurrencyValueEntity;
};

export type CustomerAttributes = {
  __typename?: 'CustomerAttributes';
  pushChristmasCalendar?: Maybe<Scalars['Boolean']>;
  pushCryptoMarket?: Maybe<Scalars['Boolean']>;
  pushCryptoNews?: Maybe<Scalars['Boolean']>;
  pushNewsletter?: Maybe<Scalars['Boolean']>;
  pushOtherMarketing?: Maybe<Scalars['Boolean']>;
  pushProductsAndCryptos?: Maybe<Scalars['Boolean']>;
};

export enum CustomerIoDeliveryEvent {
  Converted = 'converted',
  Delivered = 'delivered',
  Opened = 'opened',
}

export type CustomerIoPushAttributes = {
  pushChristmasCalendar?: InputMaybe<Scalars['Boolean']>;
  pushCryptoMarket?: InputMaybe<Scalars['Boolean']>;
  pushCryptoNews?: InputMaybe<Scalars['Boolean']>;
  pushNewsletter?: InputMaybe<Scalars['Boolean']>;
  pushOtherMarketing?: InputMaybe<Scalars['Boolean']>;
  pushProductsAndCryptos?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerIoPushMetric = {
  deliveryId: Scalars['String'];
  deviceId: Scalars['String'];
  event: CustomerIoDeliveryEvent;
  timestamp: Scalars['DateTime'];
};

export type CustomerIoRegisterInput = {
  lastUsed: Scalars['DateTime'];
  platform: Scalars['String'];
  token: Scalars['String'];
};

export type CustomerIoUser = {
  __typename?: 'CustomerIoUser';
  attributes: CustomerAttributes;
};

export type CustomerIoUserAttributes = {
  __typename?: 'CustomerIoUserAttributes';
  customer: CustomerIoUser;
};

export type DateFilter = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type DeactivateStakingInput = {
  amount: Scalars['String'];
  currency: CryptoCurrency;
};

export type DeleteContactInput = {
  address: Scalars['String'];
  currency: CryptoCurrency;
};

export type Deposit = {
  __typename?: 'Deposit';
  bankAccount: DepositBankAccount;
};

export type DepositBankAccountArgs = {
  type: DepositBankAccountType;
};

export type DepositBankAccount = {
  __typename?: 'DepositBankAccount';
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  country: Scalars['String'];
  currency: FiatCurrency;
  userIdentification: Scalars['String'];
  userIdentificationType: DepositIdentificationType;
};

export enum DepositBankAccountType {
  Manual = 'manual',
  Savings = 'savings',
}

export type DepositFilter = {
  depositStatus?: InputMaybe<Array<DepositState>>;
};

export enum DepositIdentificationType {
  Kid = 'KID',
  UserTag = 'USER_TAG',
}

export type DepositLightning = {
  __typename?: 'DepositLightning';
  invoice: Scalars['String'];
  state: Scalars['String'];
};

export enum DepositState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Incoming = 'incoming',
}

export enum DepositStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Incoming = 'incoming',
  Pending = 'pending',
}

export enum DepositType {
  ApplePayDeposit = 'ApplePayDeposit',
  BankDeposit = 'BankDeposit',
  Deposit = 'Deposit',
  GooglePayDeposit = 'GooglePayDeposit',
  MobilepayDeposit = 'MobilepayDeposit',
  SchemeDeposit = 'SchemeDeposit',
  VippsDeposit = 'VippsDeposit',
}

export type DonateInput = {
  amount: Scalars['String'];
  currency: Symbol;
  organization: Scalars['String'];
};

export type Donation = {
  __typename?: 'Donation';
  active: Scalars['Boolean'];
  currencies?: Maybe<Array<Maybe<DonationBalance_V2>>>;
  end?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  short_name: Scalars['ID'];
  start?: Maybe<Scalars['String']>;
  total_donation?: Maybe<DonationTotal>;
};

export type DonationBalance_V2 = {
  __typename?: 'DonationBalance_v2';
  amount: Scalars['String'];
  currency: Symbol;
  fiat?: Maybe<FiatBalance>;
};

export type DonationTotal = {
  __typename?: 'DonationTotal';
  currency: Symbol;
  users: Scalars['String'];
  value: Scalars['String'];
};

export type EditPurchasePriceInput = {
  accountId: Scalars['String'];
  fee?: InputMaybe<Scalars['String']>;
  fiatValue: Scalars['String'];
  income?: InputMaybe<Scalars['Boolean']>;
  transactionId: Scalars['String'];
};

export type EditPurchasePricePayload = {
  __typename?: 'EditPurchasePricePayload';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EnableOtpInput = {
  key: Scalars['String'];
  otp: Scalars['String'];
};

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
  path: Scalars['String'];
};

export enum FeeCalculationType {
  Addition = 'ADDITION',
  Subtraction = 'SUBTRACTION',
}

export type Feeback = {
  __typename?: 'Feeback';
  currency: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type FeebackPayload = {
  __typename?: 'FeebackPayload';
  payload: Array<Feeback>;
  percent: Scalars['String'];
};

export enum FeedbackFeature {
  News = 'news',
}

export enum FeedbackValue {
  Dislike = 'dislike',
  Like = 'like',
}

export type FiatBalance = {
  __typename?: 'FiatBalance';
  currency: Symbol;
  value: Scalars['String'];
};

export enum FiatCurrency {
  Dkk = 'DKK',
  Nok = 'NOK',
  Sek = 'SEK',
  Usd = 'USD',
}

export type FiatMarket = {
  __typename?: 'FiatMarket';
  id?: Maybe<Scalars['ID']>;
  symbol?: Maybe<FiatCurrency>;
};

export type FiatWithdrawLimitPayload = {
  __typename?: 'FiatWithdrawLimitPayload';
  fee: Scalars['String'];
  limit: Scalars['String'];
};

export type Flare = {
  __typename?: 'Flare';
  amount: Scalars['Float'];
  claimable: Scalars['Float'];
  time: Scalars['DateTime'];
};

export enum FormType {
  Declaration = 'DECLARATION',
  Mandatory = 'MANDATORY',
  Voluntary = 'VOLUNTARY',
}

export type GdprPayload = {
  __typename?: 'GDPRPayload';
  newsletter?: Maybe<Scalars['Boolean']>;
  profile: Scalars['Boolean'];
  v3: Scalars['Boolean'];
  v4: Scalars['Boolean'];
};

export enum GdprType {
  Profile = 'profile',
  V3 = 'v3',
  V4 = 'v4',
}

export type GenerateBankIdSessionInput = {
  platform?: InputMaybe<BankIdPlatform>;
  redirectFlow: BankIdFlow;
  redirectUrl?: InputMaybe<Scalars['String']>;
  sessionType: BankIdSessionType;
};

export type HeyLinkInput = {
  id: Scalars['String'];
};

export type IdVerificationInput = {
  redirectUrl: Scalars['String'];
};

export type IdVerificationPayload = {
  __typename?: 'IdVerificationPayload';
  url?: Maybe<Scalars['String']>;
};

export type IdVerificationResultInput = {
  dossierId: Scalars['String'];
  processId: Scalars['String'];
};

export type IdVerificationResultPayload = {
  __typename?: 'IdVerificationResultPayload';
  status: IdVerificationStatus;
};

export enum IdVerificationStatus {
  Accepted = 'accepted',
  Failed = 'failed',
  Pending = 'pending',
}

export type IntercomPayload = {
  __typename?: 'IntercomPayload';
  app_id: Scalars['String'];
  created_at: Scalars['Int'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  user_hash: Scalars['String'];
};

export type InternAccountTransfer = {
  amount: Scalars['String'];
  fromAccountId: Scalars['String'];
  toAccountId: Scalars['String'];
};

export type InvalidateBankIdSessionInput = {
  sessionId: Scalars['String'];
  sessionType: BankIdSessionType;
  status: BankIdCompleteStatus;
};

export type KeygenInput = {
  name: Scalars['String'];
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type KeygenPayload = {
  __typename?: 'KeygenPayload';
  apiKey: Scalars['String'];
  clientId: Scalars['String'];
  secretKey: Scalars['String'];
};

export type KycAnswer = {
  __typename?: 'KycAnswer';
  answerText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  respondedOn?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  subsequentquestionAnswerId?: Maybe<Scalars['Int']>;
  translationKey?: Maybe<Scalars['String']>;
};

export type KycAnswerInput = {
  answerId?: InputMaybe<Scalars['Int']>;
  answerValue: Scalars['String'];
  formServedId: Scalars['Int'];
  language: Scalars['String'];
  questionAnswerId: Scalars['Int'];
};

export type KycFormInput = {
  formTypeId: FormType;
};

export type KycFormResponse = {
  __typename?: 'KycFormResponse';
  completedOn?: Maybe<SentOn>;
  hasUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  prologue?: Maybe<Scalars['String']>;
  questions: Array<KycQuestion>;
  sentOn: SentOn;
  title: Scalars['String'];
};

export type KycInput = {
  depositFrequence: Scalars['String'];
  depositMonthly: Scalars['String'];
  depositYear: Scalars['String'];
  gdpr: Array<GdprType>;
  initialTransfer: Scalars['String'];
  jobSituation: Scalars['String'];
  moneyOrigin: Scalars['String'];
  moneyOwning: Scalars['String'];
  monthlyIncome: Scalars['String'];
  otherAccount?: InputMaybe<Scalars['String']>;
  otherMoneyOrigin?: InputMaybe<Scalars['String']>;
  pepOne?: InputMaybe<Scalars['String']>;
  pepOneCountry?: InputMaybe<Scalars['String']>;
  pepOnePosition?: InputMaybe<Scalars['String']>;
  pepOneTitle?: InputMaybe<Scalars['String']>;
  pepThreeCountry?: InputMaybe<Scalars['String']>;
  pepThreePosition?: InputMaybe<Scalars['String']>;
  pepThreeRelation?: InputMaybe<Scalars['String']>;
  pepThreeTitle?: InputMaybe<Scalars['String']>;
  pepTwo?: InputMaybe<Scalars['String']>;
  pepTwoCountry?: InputMaybe<Scalars['String']>;
  pepTwoPosition?: InputMaybe<Scalars['String']>;
  pepTwoTitle?: InputMaybe<Scalars['String']>;
  politicalPosition: Scalars['String'];
  purposeAccount: Scalars['String'];
};

export type KycMultipleAnswerInput = {
  answer: Scalars['String'];
  answerId?: InputMaybe<Scalars['Int']>;
  questionAnswerId: Scalars['Int'];
};

export type KycMultipleInput = {
  answers: Array<KycMultipleAnswerInput>;
  formServedId: Scalars['Int'];
  language: Scalars['String'];
};

export type KycQuestion = {
  __typename?: 'KycQuestion';
  answered: Array<Maybe<Scalars['String']>>;
  answers: Array<KycAnswer>;
  id: Scalars['Int'];
  isFreeText: Scalars['Boolean'];
  order: Scalars['Float'];
  required: Scalars['Boolean'];
  respondedOn?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  translationKey: Scalars['String'];
};

export type LightningInvoice = {
  __typename?: 'LightningInvoice';
  address: Scalars['String'];
  destinationTag?: Maybe<Scalars['String']>;
};

export type Lookup = {
  __typename?: 'Lookup';
  country?: Maybe<Scalars['String']>;
};

export type Market = {
  __typename?: 'Market';
  askPriceLowest?: Maybe<Scalars['String']>;
  bars?: Maybe<Array<Maybe<Bar>>>;
  baseCurrency?: Maybe<Currency>;
  baseSymbol?: Maybe<CryptoCurrency>;
  bidPriceHighest?: Maybe<Scalars['String']>;
  buyOrders: Array<Array<Scalars['String']>>;
  change?: Maybe<Scalars['String']>;
  enabled?: Maybe<MarketsEnabled>;
  frozen?: Maybe<Scalars['Boolean']>;
  hasAdvanced?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<MarketTrade>>>;
  id: Scalars['ID'];
  lastPrice?: Maybe<Scalars['String']>;
  marketType?: Maybe<MarketType>;
  priceHistory: MarketPriceHistory;
  quoteCurrency?: Maybe<Currency>;
  quoteSymbol?: Maybe<FiatCurrency>;
  sellOrders: Array<Array<Scalars['String']>>;
  symbol: Scalars['ID'];
  /** @deprecated Get this from whoami */
  transactionFee?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
};

export type MarketBarsArgs = {
  input: MarketInput;
};

export type MarketHistoryArgs = {
  input?: InputMaybe<MarketHistoryInput>;
};

export type MarketPriceHistoryArgs = {
  period: PnlPeriod;
};

export type MarketHistoryInput = {
  count?: InputMaybe<Scalars['Int']>;
};

export type MarketInput = {
  from?: InputMaybe<Scalars['String']>;
  resolution?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['ID']>;
  to?: InputMaybe<Scalars['String']>;
};

export type MarketOrderPayload = {
  __typename?: 'MarketOrderPayload';
  assetMatched: Scalars['String'];
  exchangeRate: Scalars['String'];
  fee: Scalars['String'];
  fiatMatched: Scalars['String'];
  status?: Maybe<OrderStatus>;
};

export type MarketOrderResponse = {
  __typename?: 'MarketOrderResponse';
  errorMsg?: Maybe<Scalars['String']>;
  result?: Maybe<MarketOrderPayload>;
};

export type MarketPriceHistory = {
  __typename?: 'MarketPriceHistory';
  change?: Maybe<Scalars['Float']>;
  data: Array<Maybe<MarketPriceHistoryTick>>;
};

export type MarketPriceHistoryTick = {
  __typename?: 'MarketPriceHistoryTick';
  date: Scalars['DateTime'];
  price: Scalars['String'];
};

export type MarketTrade = {
  __typename?: 'MarketTrade';
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  price: Scalars['String'];
  type: Scalars['String'];
};

export enum MarketType {
  Orderbook = 'orderbook',
  Otc = 'otc',
}

export type MarketsEnabled = {
  __typename?: 'MarketsEnabled';
  buy?: Maybe<Scalars['Boolean']>;
  sell?: Maybe<Scalars['Boolean']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  favorites?: Maybe<Array<CryptoCurrency>>;
  fraudAcknowledge?: Maybe<Scalars['Boolean']>;
  hasDeposited?: Maybe<Scalars['Boolean']>;
  hasSavingsAccount?: Maybe<Scalars['Boolean']>;
  hasSavingsAgreement?: Maybe<Scalars['Boolean']>;
  hasStakingAccount?: Maybe<Scalars['Boolean']>;
  hasTraded?: Maybe<Scalars['Boolean']>;
  hasTransaction?: Maybe<Scalars['Boolean']>;
  memeConsent?: Maybe<Scalars['Boolean']>;
  readArticles?: Maybe<Array<Scalars['String']>>;
};

export enum MetadataField {
  FraudAcknowledge = 'fraudAcknowledge',
  MemeConsent = 'memeConsent',
}

export type MetadataInput = {
  key?: InputMaybe<MetadataField>;
  value?: InputMaybe<Scalars['String']>;
};

export type MiscFilter = {
  transactionTypes?: InputMaybe<Array<TransactionsMiscTypes>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateStaking: SuccessPayload;
  adTractionConversion: SuccessPayload;
  addContact: SuccessPayload;
  adyenSubmitPayment: AdyenSubmitPaymentPayload;
  adyenSubmitPaymentDetails: AdyenSubmitPaymentDetailsPayload;
  alterConsent: Scalars['Boolean'];
  calculatePaymentFee: CalculatePaymentFeePayload;
  cancelLightningInvoices: Scalars['String'];
  cancelOrder: SuccessPayload;
  cancelWithdraw: CancelWithdrawPayload;
  changePassword: SuccessPayload;
  changeUnverifiedEmail: SuccessPayload;
  checkReferralCode?: Maybe<AffiliateOwnerPayload>;
  claimReward: ClaimRewardPayload;
  createAccount: CreateAccountPayload;
  createAccountGroup: CreateAccountGroupPayload;
  createBankAccount: BankAccount;
  createLightningInvoice?: Maybe<LightningInvoice>;
  createMarketOrder: CreateMarketOrderPayload;
  createSavingAgreement: SuccessPayload;
  createVoucherBankId: CreateVoucherBankIdPayload;
  deactivateStaking: SuccessPayload;
  deleteApiKey: Scalars['Boolean'];
  deleteContact: SuccessPayload;
  deleteProfilePicture: SuccessPayload;
  donate: SuccessPayload;
  editPurchasePrice: EditPurchasePricePayload;
  enableOtp: SuccessPayload;
  generateApiKey: KeygenPayload;
  generateBankIdSession: BankIdSessionPayload;
  heyLinkConversion: SuccessPayload;
  initializeOtp: SuccessPayload;
  internAccountTransfer: SuccessPayload;
  invalidateBankIdSession: SuccessPayload;
  neonomicsInitiateSession: NeonomicsInitiateSessionPayload;
  placeMarketOrder: MarketOrderResponse;
  placeMarketOrderOtc?: Maybe<MarketOrderResponse>;
  placeOrder: OrderResponse;
  rebuildTax: RebuildTaxPayload;
  redeemVoucher: RedeemVoucherPayload;
  registerCustomerIoPushToken: SuccessPayload;
  registerPushToken: SuccessPayload;
  removeBankAccount: SuccessPayload;
  removeOtp: SuccessPayload;
  requestPasswordResetCode_v2: PasswordResetCodePayload;
  requestPasswordReset_v2: SuccessPayload;
  requestWithdrawLightning: WithdrawLightning;
  resendVerificationCode: SuccessPayload;
  resetPassword_v2: SuccessPayload;
  sendMultipleAnswer: KycFormResponse;
  setAirdropAddress: SuccessPayload;
  setMetadata: SuccessPayload;
  submitCalendarAnswer: SubmitCalendarAnswerPayload;
  submitCrypto: SuccessPayload;
  submitFeedback: SuccessPayload;
  submitKYCAnswer: KycFormResponse;
  submitSurvey: SuccessPayload;
  submitSurveyFirestore: SuccessPayload;
  submitTranslation: SuccessPayload;
  submitUserNin: SuccessPayload;
  tinkInitiateAccounts: TinkInitiateAccountsPayload;
  tinkInitiatePayment: TinkInitiatePaymentPayload;
  tinkUpdatePayment: TinkUpdatePaymentPayload;
  toggle2fa?: Maybe<Scalars['Int']>;
  toggle2fa_v2: SuccessPayload;
  toggleFavorite: SuccessPayload;
  toggleReadArticle: SuccessPayload;
  trackCustomerIoPushMetrics: SuccessPayload;
  trackWidget: SuccessPayload;
  unRegisterPushToken: SuccessPayload;
  updateAccountGroup: SuccessPayload;
  updateApiKey: Scalars['Boolean'];
  updateCustomerIoPushAttributes: SuccessPayload;
  updatePushPreference: SuccessPayload;
  updateSavingAgreement: SuccessPayload;
  updateSavingAgreementDetails: SuccessPayload;
  updateUserProfile_v2: Profile;
  validateBankIdSession: SuccessPayload;
  validateKycBankId: SuccessPayload;
  verifyEmailCode: SuccessPayload;
  verifyUser: SuccessPayload;
  withdrawCryptoBankId: SuccessPayload;
  withdrawFiatBankId: SuccessPayload;
  withdrawKycConsent: SuccessPayload;
};

export type MutationActivateStakingArgs = {
  input: ActivateStakingInput;
};

export type MutationAdTractionConversionArgs = {
  input: AdTractionInput;
};

export type MutationAddContactArgs = {
  input?: InputMaybe<AddContactInput>;
};

export type MutationAdyenSubmitPaymentArgs = {
  input: AdyenSubmitPaymentInput;
};

export type MutationAdyenSubmitPaymentDetailsArgs = {
  input: AdyenSubmitPaymentDetailsInput;
};

export type MutationAlterConsentArgs = {
  input: ConsentInput;
};

export type MutationCalculatePaymentFeeArgs = {
  input: CalculatePaymentFeeInput;
};

export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};

export type MutationCancelWithdrawArgs = {
  input?: InputMaybe<CancelWithdrawInput>;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationChangeUnverifiedEmailArgs = {
  email: Scalars['String'];
};

export type MutationCheckReferralCodeArgs = {
  code: Scalars['String'];
};

export type MutationClaimRewardArgs = {
  uuid: Scalars['String'];
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type MutationCreateAccountGroupArgs = {
  input: CreateAccountGroup;
};

export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};

export type MutationCreateLightningInvoiceArgs = {
  input?: InputMaybe<CreateLightningInvoiceInput>;
};

export type MutationCreateMarketOrderArgs = {
  input: CreateMarketOrderInput;
};

export type MutationCreateSavingAgreementArgs = {
  input: CreateSavingAgreement;
};

export type MutationCreateVoucherBankIdArgs = {
  input?: InputMaybe<CreateVoucherBankIdInput>;
};

export type MutationDeactivateStakingArgs = {
  input: DeactivateStakingInput;
};

export type MutationDeleteApiKeyArgs = {
  apiKey: Scalars['String'];
};

export type MutationDeleteContactArgs = {
  input?: InputMaybe<DeleteContactInput>;
};

export type MutationDonateArgs = {
  input: DonateInput;
};

export type MutationEditPurchasePriceArgs = {
  input: EditPurchasePriceInput;
};

export type MutationEnableOtpArgs = {
  input: EnableOtpInput;
};

export type MutationGenerateApiKeyArgs = {
  input: KeygenInput;
};

export type MutationGenerateBankIdSessionArgs = {
  input?: InputMaybe<GenerateBankIdSessionInput>;
};

export type MutationHeyLinkConversionArgs = {
  input?: InputMaybe<HeyLinkInput>;
};

export type MutationInitializeOtpArgs = {
  code: Scalars['String'];
};

export type MutationInternAccountTransferArgs = {
  input: InternAccountTransfer;
};

export type MutationInvalidateBankIdSessionArgs = {
  input: InvalidateBankIdSessionInput;
};

export type MutationNeonomicsInitiateSessionArgs = {
  input: NeonomicsInitiateSessionInput;
};

export type MutationPlaceMarketOrderArgs = {
  input: PlaceOrderInput;
};

export type MutationPlaceMarketOrderOtcArgs = {
  input: PlaceOrderInput;
};

export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput;
};

export type MutationRedeemVoucherArgs = {
  id: Scalars['String'];
};

export type MutationRegisterCustomerIoPushTokenArgs = {
  input: CustomerIoRegisterInput;
};

export type MutationRegisterPushTokenArgs = {
  input: PushNotificationInput;
};

export type MutationRemoveBankAccountArgs = {
  input: RemoveBankAccountInput;
};

export type MutationRemoveOtpArgs = {
  code: Scalars['String'];
};

export type MutationRequestPasswordResetCode_V2Args = {
  input: RequestPasswordResetCodeInput;
};

export type MutationRequestPasswordReset_V2Args = {
  input: RequestPasswordResetInput;
};

export type MutationRequestWithdrawLightningArgs = {
  input: RequestWithdrawLightningInput;
};

export type MutationResetPassword_V2Args = {
  input: ResetPasswordInput;
};

export type MutationSendMultipleAnswerArgs = {
  input: KycMultipleInput;
};

export type MutationSetAirdropAddressArgs = {
  input?: InputMaybe<AirdropAddressInput>;
};

export type MutationSetMetadataArgs = {
  input: MetadataInput;
};

export type MutationSubmitCalendarAnswerArgs = {
  input: SubmitCalendarAnswerInput;
};

export type MutationSubmitCryptoArgs = {
  input?: InputMaybe<SubmitCryptoInput>;
};

export type MutationSubmitFeedbackArgs = {
  input: SubmitFeedbackInput;
};

export type MutationSubmitKycAnswerArgs = {
  input: KycAnswerInput;
};

export type MutationSubmitSurveyArgs = {
  input?: InputMaybe<SubmitSurveyInput>;
};

export type MutationSubmitSurveyFirestoreArgs = {
  input: SurveyInput;
};

export type MutationSubmitTranslationArgs = {
  input: TranslationInput;
};

export type MutationSubmitUserNinArgs = {
  nin: Scalars['String'];
};

export type MutationTinkInitiateAccountsArgs = {
  input: TinkInitiateAccountsInput;
};

export type MutationTinkInitiatePaymentArgs = {
  input: TinkInitiatePaymentInput;
};

export type MutationTinkUpdatePaymentArgs = {
  input: TinkUpdatePaymentInput;
};

export type MutationToggle2faArgs = {
  input: EnableOtpInput;
};

export type MutationToggle2fa_V2Args = {
  input: EnableOtpInput;
};

export type MutationToggleFavoriteArgs = {
  input: ToggleFavoriteInput;
};

export type MutationToggleReadArticleArgs = {
  input: ToggleReadArticleInput;
};

export type MutationTrackCustomerIoPushMetricsArgs = {
  input: CustomerIoPushMetric;
};

export type MutationTrackWidgetArgs = {
  input: TrackWidgetInput;
};

export type MutationUnRegisterPushTokenArgs = {
  input: PushNotificationInput;
};

export type MutationUpdateAccountGroupArgs = {
  input: UpdateAccountGroup;
};

export type MutationUpdateApiKeyArgs = {
  apiKey: ApiKeyInput;
};

export type MutationUpdateCustomerIoPushAttributesArgs = {
  input: CustomerIoPushAttributes;
};

export type MutationUpdatePushPreferenceArgs = {
  input: PushNotificationPreferenceInput;
};

export type MutationUpdateSavingAgreementArgs = {
  input: CreateSavingAgreement;
};

export type MutationUpdateSavingAgreementDetailsArgs = {
  input: UpdateSavingAgreementDetails;
};

export type MutationUpdateUserProfile_V2Args = {
  input: UpdateUserProfileInput_V2;
};

export type MutationValidateBankIdSessionArgs = {
  input: InvalidateBankIdSessionInput;
};

export type MutationValidateKycBankIdArgs = {
  input: ValidateKycBankIdInput;
};

export type MutationVerifyEmailCodeArgs = {
  code: Scalars['String'];
};

export type MutationVerifyUserArgs = {
  code: Scalars['String'];
};

export type MutationWithdrawCryptoBankIdArgs = {
  input: WithdrawCryptoBankIdInput;
};

export type MutationWithdrawFiatBankIdArgs = {
  input: WithdrawFiatBankIdInput;
};

export type NeonomicsInitiateSessionInput = {
  id: Scalars['String'];
  redirectUrl?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
};

export type NeonomicsInitiateSessionPayload = {
  __typename?: 'NeonomicsInitiateSessionPayload';
  deviceId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export enum OnboardingStatusEnum {
  Accepted = 'Accepted',
  NeedManualProcessing = 'NeedManualProcessing',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Unspecified = 'Unspecified',
}

export type OpenBankingAccount = {
  __typename?: 'OpenBankingAccount';
  accountName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  balances?: Maybe<Array<OpenBankingAccountBalance>>;
  bban?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  limitedTo?: Maybe<Scalars['Int']>;
};

export type OpenBankingAccountBalance = {
  __typename?: 'OpenBankingAccountBalance';
  amount: Scalars['String'];
  currency: Scalars['String'];
  type: Scalars['String'];
};

export type OpenBankingAccountInput = {
  deviceId: Scalars['String'];
  sessionId: Scalars['String'];
  ssn?: InputMaybe<Scalars['String']>;
};

export type OpenBankingBank = {
  __typename?: 'OpenBankingBank';
  bankDisplayName: Scalars['String'];
  bankOfficialName: Scalars['String'];
  bankingGroupName: Scalars['String'];
  bic: Scalars['String'];
  countryCode: OpenBankingCountryCode;
  id: Scalars['String'];
  personalIdentificationRequired: Scalars['Boolean'];
  status: Scalars['String'];
  supportedServices: Array<Scalars['String']>;
};

export type OpenBankingComplete = {
  __typename?: 'OpenBankingComplete';
  amount?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
};

export type OpenBankingCompleteInput = {
  deviceId: Scalars['String'];
  sessionId: Scalars['String'];
};

export enum OpenBankingCountryCode {
  Dk = 'DK',
  No = 'NO',
  Se = 'SE',
}

export enum OpenBankingPaymentConsent {
  Pending = 'pending',
  Success = 'success',
}

export type OpenBankingProvider = {
  __typename?: 'OpenBankingProvider';
  id: Scalars['ID'];
  limitedTo?: Maybe<Scalars['Int']>;
  name: OpenBankingProviderName;
  personalIdentificationRequired?: Maybe<Scalars['Boolean']>;
};

export enum OpenBankingProviderName {
  Neonomics = 'neonomics',
  Tink = 'tink',
}

export type OpenBankingSession = {
  __typename?: 'OpenBankingSession';
  deviceId: Scalars['String'];
  sessionID: Scalars['String'];
  url: Scalars['String'];
};

export type OpenBankingSessionInput = {
  bankId: Scalars['String'];
  redirectUrl?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
};

export type OpenBankingTransfer = {
  __typename?: 'OpenBankingTransfer';
  errorMsg?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  status?: Maybe<OpenBankingPaymentConsent>;
  url?: Maybe<Scalars['String']>;
};

export type OpenBankingTransferInput = {
  accountId: Scalars['String'];
  amount: Scalars['String'];
  currency?: InputMaybe<FiatCurrency>;
  deviceId: Scalars['String'];
  redirectUrl?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
  ssn?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['String'];
  cancelled: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  market: Market;
  price: Scalars['String'];
  remaining: Scalars['String'];
  type: OrderType;
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  error?: Maybe<Error>;
  ok: Scalars['Boolean'];
};

export enum OrderStatus {
  Failed = 'Failed',
  Fulfilled = 'Fulfilled',
  Pending = 'Pending',
}

export enum OrderType {
  Buy = 'buy',
  Sell = 'sell',
}

export type Otc = {
  __typename?: 'Otc';
  prices?: Maybe<OtcPrices>;
  tradeVolume: Array<TradeVolume>;
};

export type OtcPricesArgs = {
  base: CryptoCurrency;
  quote: FiatCurrency;
};

export type OtcTradeVolumeArgs = {
  fiatMarket: FiatCurrency;
};

export type OtcPrices = {
  __typename?: 'OtcPrices';
  ask: Scalars['String'];
  bid: Scalars['String'];
  maxAskVolume: Scalars['String'];
  maxBidVolume: Scalars['String'];
};

export type OtpPayload = {
  __typename?: 'OtpPayload';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Pnl = {
  __typename?: 'PNL';
  balance?: Maybe<PnlBalance>;
  priceStats?: Maybe<PriceStats>;
  yield?: Maybe<YieldData>;
  yield_v2?: Maybe<YieldData>;
};

export type PnlBalanceArgs = {
  input: PnlInput;
};

export type PnlPriceStatsArgs = {
  accountGroupId?: InputMaybe<Scalars['String']>;
  currency: CryptoCurrency;
};

export type PnlYieldArgs = {
  input: PnlInput;
};

export type PnlYield_V2Args = {
  input: PnlInput;
};

export type PnlBalance = {
  __typename?: 'PNLBalance';
  currencies?: Maybe<Array<PnlBalanceCurrency>>;
  fiatCurrency: FiatCurrency;
  restrictedMode?: Maybe<Scalars['Boolean']>;
  total: YieldTotal;
};

export type PnlBalanceCurrency = {
  __typename?: 'PNLBalanceCurrency';
  cryptoBalance: Scalars['String'];
  cryptoCurrency: CryptoCurrency;
  fiatAmount: Scalars['String'];
  fiatBalance: Scalars['String'];
  percent: Scalars['String'];
};

export type PnlInput = {
  accountGroupId?: InputMaybe<Scalars['String']>;
  period: PnlPeriod;
};

export enum PnlPeriod {
  All = 'all',
  Day = 'day',
  Month = 'month',
  ThreeMonth = 'threeMonth',
  Week = 'week',
  Year = 'year',
}

export type PasswordResetCodePayload = {
  __typename?: 'PasswordResetCodePayload';
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Payment = {
  __typename?: 'Payment';
  adyenLimits?: Maybe<AdyenLimits>;
  adyenPaymentMethods?: Maybe<Array<AdyenPaymentMethods>>;
  aydenPaymentStatus?: Maybe<AdyenPaymentStatus>;
  openbankingBanks: Array<BankOpenBanking>;
  paymentDirectOrderStatus: PaymentDirectOrderStatus;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  paymentStatus: DepositStatus;
  tinkAccounts?: Maybe<Array<TinkAccount>>;
};

export type PaymentAdyenPaymentMethodsArgs = {
  platform: AdyenPlatform;
};

export type PaymentAydenPaymentStatusArgs = {
  merchantReference: Scalars['String'];
};

export type PaymentOpenbankingBanksArgs = {
  country: OpenBankingCountryCode;
  forceNeonomics?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentPaymentDirectOrderStatusArgs = {
  merchantReference: Scalars['String'];
};

export type PaymentPaymentStatusArgs = {
  id: Scalars['String'];
};

export type PaymentTinkAccountsArgs = {
  accountCode?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
};

export type PaymentDirectOrderStatus = {
  __typename?: 'PaymentDirectOrderStatus';
  depositFee: Scalars['String'];
  order?: Maybe<MarketOrderPayload>;
  paymentStatus: AdyenPaymentStatus;
};

export type PaymentMarketOrderInput = {
  baseCurrency: CryptoCurrency;
  price: Scalars['String'];
  priceSlippage?: InputMaybe<Scalars['Float']>;
  quoteCurrency: FiatCurrency;
  slippage?: InputMaybe<Scalars['Int']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  fastest: Scalars['Boolean'];
  hasFee: Scalars['Boolean'];
  instantSettlement: Scalars['Boolean'];
  type: PaymentMethodType;
};

export enum PaymentMethodType {
  Applepay = 'applepay',
  Googlepay = 'googlepay',
  Manual = 'manual',
  Mobilepay = 'mobilepay',
  Openbanking = 'openbanking',
  Paywithgoogle = 'paywithgoogle',
  Scheme = 'scheme',
  Vipps = 'vipps',
}

export type Payout = {
  __typename?: 'Payout';
  currency?: Maybe<Symbol>;
  pending?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['String']>;
};

export type PlaceOrderInput = {
  amount: Scalars['String'];
  isOtc?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['String'];
  priceSlippage?: InputMaybe<Scalars['Float']>;
  slippage?: InputMaybe<Scalars['Int']>;
  symbol: Scalars['String'];
  type: OrderType;
};

export type PreviousTranslationInput = {
  locale: Scalars['String'];
  originalText: Scalars['String'];
};

export type PriceIndexPayload = {
  __typename?: 'PriceIndexPayload';
  rate: Scalars['String'];
};

export type PriceStats = {
  __typename?: 'PriceStats';
  avgPrice?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  maxPrice?: Maybe<Scalars['String']>;
  minPrice?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  actions?: Maybe<Array<Maybe<UserNeededAction>>>;
  actions_v2?: Maybe<Array<Maybe<UserNeededAction>>>;
  address?: Maybe<Scalars['String']>;
  affiliateCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Contact>>;
  corporate: Corporate;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: FiatCurrency;
  dateOfBirth?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  features: Array<Scalars['String']>;
  fee: Scalars['Float'];
  fiatMarket: FiatMarket;
  fiatWithdrawLimit: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  hasVerifiedEmail: Scalars['Boolean'];
  hasVippsLinked: Scalars['Boolean'];
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated use manualDepositBankAccountV2 */
  manualDepositBankAccount?: Maybe<Scalars['String']>;
  manualDepositBankAccountV2?: Maybe<Scalars['String']>;
  mustVerifyEmail: Scalars['Boolean'];
  nationality?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<OnboardingStatusEnum>;
  phone?: Maybe<Scalars['String']>;
  postalArea?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  profilePictureUploadUrl?: Maybe<Scalars['String']>;
  securityLevel: Scalars['Int'];
  tag: Scalars['String'];
  transactionFee?: Maybe<Scalars['String']>;
  twoFactor: Scalars['Boolean'];
  type: Scalars['String'];
  /** @deprecated use bankAccount in Deposit scheme instead */
  userDepositBankAccount: UserDepositBankAccount;
  uuid?: Maybe<Scalars['String']>;
};

export type ProfileContactsArgs = {
  currency?: InputMaybe<CryptoCurrency>;
};

export type ProfileUserDepositBankAccountArgs = {
  type: DepositBankAccountType;
};

export type PushNotificationInput = {
  token: Scalars['String'];
};

export type PushNotificationPreference = {
  __typename?: 'PushNotificationPreference';
  accountActivityEmailCommunication: Scalars['Boolean'];
  accountActivityPushCommunication: Scalars['Boolean'];
  firiFordelEmailCommunication: Scalars['Boolean'];
  firiFordelPushCommunication: Scalars['Boolean'];
};

export type PushNotificationPreferenceInput = {
  accountActivityEmailCommunication: Scalars['Boolean'];
  accountActivityPushCommunication: Scalars['Boolean'];
  firiFordelEmailCommunication: Scalars['Boolean'];
  firiFordelPushCommunication: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  account: AccountEntity;
  activeLightningInvoices: Array<Scalars['String']>;
  activities: Array<Activity>;
  affiliate?: Maybe<Affiliate>;
  affiliateStats?: Maybe<AffiliateStats>;
  airdrop: Airdrop;
  apiKeys: Array<ApiKey>;
  appVersion?: Maybe<AppVersion>;
  bankAccounts: Array<BankAccount>;
  banners: Array<Maybe<Banner>>;
  calendar?: Maybe<Calendar>;
  currency_v2?: Maybe<Currency>;
  currentUser?: Maybe<Profile>;
  customerIoAttributes?: Maybe<CustomerIoUserAttributes>;
  deposit: Deposit;
  donation?: Maybe<Donation>;
  donations_v2?: Maybe<Array<Maybe<Donation>>>;
  /** @deprecated does not support country. use withdraw.withdrawLimit */
  fiatWithdrawLimit: FiatWithdrawLimitPayload;
  gdprConsent: GdprPayload;
  getPushPreferences?: Maybe<PushNotificationPreference>;
  idVerificationResult: IdVerificationResultPayload;
  idVerificationUrl: IdVerificationPayload;
  intercom?: Maybe<IntercomPayload>;
  isOtpRequired?: Maybe<Scalars['Boolean']>;
  isOtpRequired_V2: OtpPayload;
  kycForm?: Maybe<KycFormResponse>;
  lightningDeposit: DepositLightning;
  lookup: Lookup;
  market_v2?: Maybe<Market>;
  market_v3?: Maybe<Market>;
  markets: Array<Market>;
  metadata: Metadata;
  openBankingAccounts?: Maybe<Array<OpenBankingAccount>>;
  openBankingBanks: Array<OpenBankingBank>;
  openBankingCompleteTransfer: OpenBankingComplete;
  openBankingDomesticTransfer: OpenBankingTransfer;
  openBankingInitiateSession: OpenBankingSession;
  orders?: Maybe<Array<Order>>;
  otc: Otc;
  payment: Payment;
  pnl?: Maybe<Pnl>;
  previousTranslation?: Maybe<TranslationPayload>;
  priceIndex?: Maybe<PriceIndexPayload>;
  referralBonus?: Maybe<ReferralBonus>;
  referralCount?: Maybe<ReferralCount>;
  referralOwner_v3: AffiliateOwnerPayload;
  search_v3?: Maybe<Search>;
  shouldUpdateSavingsDeposit: Scalars['Boolean'];
  staking: Staking;
  system: System;
  tax?: Maybe<Tax>;
  trades?: Maybe<TradeEntity>;
  transfers?: Maybe<Transfer>;
  verifyCaptchaToken?: Maybe<RecapthaPayload>;
  voucher?: Maybe<VoucherEntity>;
  withdraw: WithdrawEntity;
  withdraws: Array<Withdraw>;
  withdrawsLightning: Array<WithdrawLightning>;
};

export type QueryBannersArgs = {
  input?: InputMaybe<BannersInput>;
};

export type QueryCurrency_V2Args = {
  symbol: Symbol;
};

export type QueryDonationArgs = {
  organization: Scalars['String'];
};

export type QueryIdVerificationResultArgs = {
  input: IdVerificationResultInput;
};

export type QueryIdVerificationUrlArgs = {
  input: IdVerificationInput;
};

export type QueryKycFormArgs = {
  input: KycFormInput;
};

export type QueryLightningDepositArgs = {
  invoice: Scalars['String'];
};

export type QueryMarket_V2Args = {
  marketType?: InputMaybe<MarketType>;
  symbol: Scalars['ID'];
};

export type QueryMarket_V3Args = {
  baseCurrency: CryptoCurrency;
  marketType?: InputMaybe<MarketType>;
  quoteCurrency: FiatCurrency;
};

export type QueryMarketsArgs = {
  includeOtc?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOpenBankingAccountsArgs = {
  input: OpenBankingAccountInput;
};

export type QueryOpenBankingCompleteTransferArgs = {
  input: OpenBankingCompleteInput;
};

export type QueryOpenBankingDomesticTransferArgs = {
  input: OpenBankingTransferInput;
};

export type QueryOpenBankingInitiateSessionArgs = {
  input: OpenBankingSessionInput;
};

export type QueryOrdersArgs = {
  currency?: InputMaybe<Symbol>;
};

export type QueryPreviousTranslationArgs = {
  input: PreviousTranslationInput;
};

export type QueryPriceIndexArgs = {
  symbol: Scalars['ID'];
};

export type QueryReferralBonusArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export type QueryReferralOwner_V3Args = {
  code: Scalars['String'];
};

export type QuerySearch_V3Args = {
  accountGroupId?: InputMaybe<Array<Scalars['String']>>;
  count: Scalars['Int'];
  entities: Array<SearchEntity>;
  filter?: InputMaybe<SearchInputFilter>;
  nextPage?: InputMaybe<Array<SearchInputNextPage>>;
};

export type QueryVerifyCaptchaTokenArgs = {
  token: Scalars['String'];
};

export type QueryWithdrawsArgs = {
  after?: InputMaybe<Scalars['String']>;
};

export enum RebuildCause {
  NewVersion = 'new_version',
  PendingPrice = 'pending_price',
}

export type RebuildTaxPayload = {
  __typename?: 'RebuildTaxPayload';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RecapthaPayload = {
  __typename?: 'RecapthaPayload';
  uuid: Scalars['String'];
};

export type RedeemVoucherPayload = {
  __typename?: 'RedeemVoucherPayload';
  amount?: Maybe<Scalars['String']>;
  currency?: Maybe<CryptoCurrency>;
  error?: Maybe<Scalars['String']>;
};

export type Referral = {
  __typename?: 'Referral';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payout?: Maybe<ReferralPayout>;
  referredAt: Scalars['DateTime'];
  status?: Maybe<ReferralStatus>;
};

export type ReferralBonus = {
  __typename?: 'ReferralBonus';
  amount?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  payoutCurrency?: Maybe<CryptoCurrency>;
  welcomeBonus?: Maybe<Scalars['String']>;
  welcomeBonusCurrency?: Maybe<Scalars['String']>;
};

export type ReferralCount = {
  __typename?: 'ReferralCount';
  last: Scalars['String'];
  pending: Scalars['String'];
  total: Scalars['String'];
};

export type ReferralCountTotal = {
  __typename?: 'ReferralCountTotal';
  completed?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
};

export type ReferralPayload = {
  __typename?: 'ReferralPayload';
  code: Scalars['String'];
  count: Scalars['Int'];
};

export type ReferralPayout = {
  __typename?: 'ReferralPayout';
  amount?: Maybe<Scalars['String']>;
  creditedAt?: Maybe<Scalars['String']>;
  currency?: Maybe<FiatCurrency>;
  paidAt?: Maybe<Scalars['String']>;
  payoutAmount?: Maybe<Scalars['String']>;
  payoutCurrency?: Maybe<Symbol>;
};

export enum ReferralStatus {
  Complete = 'complete',
  Invalid = 'invalid',
  PendingPayments = 'pendingPayments',
  PendingUser = 'pendingUser',
}

export type RemoveBankAccountInput = {
  accountId: Scalars['String'];
};

export type RequestPasswordResetCodeInput = {
  code: Scalars['String'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export type RequestWithdrawLightningInput = {
  amountSat?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  key: Scalars['String'];
  pw?: InputMaybe<Scalars['String']>;
};

export type Reward = {
  __typename?: 'Reward';
  amount: Scalars['String'];
  currency: FiatCurrency;
  payoutCurrency: Symbol;
};

export enum RewardTypes {
  EasterBonus = 'EasterBonus',
  First250NokDeposit = 'First250NOKDeposit',
  FirstCryptoDeposit = 'FirstCryptoDeposit',
  FirstNokDeposit = 'FirstNOKDeposit',
  FirstSave = 'FirstSave',
  FirstTrade = 'FirstTrade',
  NewWelcome = 'NewWelcome',
  ReferralReward = 'ReferralReward',
  ReferralReward2022 = 'ReferralReward2022',
  ReferralReward2023 = 'ReferralReward2023',
  Trade3x2500 = 'Trade3x2500',
  Trade1000 = 'Trade1000',
  Trade20000 = 'Trade20000',
  TradeAll2000 = 'TradeAll2000',
  TradeOver15000 = 'TradeOver15000',
  WelcomeBonus = 'WelcomeBonus',
}

export type SavingAccountGroup = AccountGroup & {
  __typename?: 'SavingAccountGroup';
  accounts?: Maybe<Array<Account>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  savingsAgreement?: Maybe<SavingAgreement>;
  totalFiatValue: AccountTotalFiatValue;
  type: AccountType;
  uuid: Scalars['String'];
};

export type SavingAgreement = {
  __typename?: 'SavingAgreement';
  accountGroupId: Scalars['String'];
  amount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  recurring?: Maybe<Scalars['Int']>;
  savingDetails?: Maybe<Array<SavingAgreementDetail>>;
};

export type SavingAgreementDetail = {
  __typename?: 'SavingAgreementDetail';
  currency: Symbol;
  distribution: Scalars['Float'];
  id: Scalars['ID'];
};

export type Search = {
  __typename?: 'Search';
  hasMoreData: Scalars['Boolean'];
  nextPage: Array<SearchNextPage>;
  results: Array<SearchUnion>;
};

export enum SearchEntity {
  Deposit = 'deposit',
  Misc = 'misc',
  Trade = 'trade',
  Withdraw = 'withdraw',
}

export type SearchInputFilter = {
  currency?: InputMaybe<Symbol>;
  date?: InputMaybe<DateFilter>;
  deposit?: InputMaybe<DepositFilter>;
  excludeCurrency?: InputMaybe<Symbol>;
  includeFiatValue?: InputMaybe<Scalars['Boolean']>;
  misc?: InputMaybe<MiscFilter>;
  trade?: InputMaybe<TradeFilter>;
  withdraw?: InputMaybe<WithdrawFilter>;
};

export type SearchInputNextPage = {
  afterId?: InputMaybe<Scalars['String']>;
  entity: Scalars['String'];
};

export type SearchNextPage = {
  __typename?: 'SearchNextPage';
  afterId?: Maybe<Scalars['String']>;
  entity: Scalars['String'];
};

export type SearchUnion = Trade | TransactionMisc | TransferDeposit | TransferWithdraw;

export type SentOn = {
  __typename?: 'SentOn';
  nanos?: Maybe<Scalars['Float']>;
  seconds?: Maybe<Scalars['Float']>;
};

export type StakedStakingAsset = StakingAssetBase & {
  __typename?: 'StakedStakingAsset';
  accounts?: Maybe<Array<StakingAccounts>>;
  apy?: Maybe<Scalars['Float']>;
  currency: CryptoCurrency;
  epochLength?: Maybe<Scalars['Float']>;
  fiatCurrency: FiatCurrency;
  lockPeriod?: Maybe<Scalars['Float']>;
  minimumStaked?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  rewardDelay?: Maybe<Scalars['Float']>;
  stats?: Maybe<StakingAssetStats>;
  type: StakingType;
};

export type Staking = {
  __typename?: 'Staking';
  currencies?: Maybe<Array<StakingAsset>>;
  currency?: Maybe<StakingAsset>;
  estimatedUnstakeTime?: Maybe<UnstakeTime>;
  notStakedCurrencies?: Maybe<Array<StakingAsset>>;
  notStakedCurrenciesWithHold?: Maybe<Array<StakingAsset>>;
  stakedCurrencies?: Maybe<Array<StakedStakingAsset>>;
  stakedCurrency?: Maybe<StakedStakingAsset>;
  stats?: Maybe<StakingStats>;
  unstakingRequests?: Maybe<Array<UnstakingRequests>>;
};

export type StakingCurrencyArgs = {
  currency: CryptoCurrency;
};

export type StakingEstimatedUnstakeTimeArgs = {
  amount: Scalars['String'];
  currency: CryptoCurrency;
};

export type StakingStakedCurrencyArgs = {
  currency: CryptoCurrency;
};

export type StakingUnstakingRequestsArgs = {
  currency?: InputMaybe<CryptoCurrency>;
  status?: InputMaybe<Array<UnstakingRequestStatus>>;
};

export type StakingAccountGroup = AccountGroup & {
  __typename?: 'StakingAccountGroup';
  accounts?: Maybe<Array<Account>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  totalFiatValue: AccountTotalFiatValue;
  type: AccountType;
  uuid: Scalars['String'];
};

export type StakingAccounts = {
  __typename?: 'StakingAccounts';
  accountGroupId: Scalars['String'];
  stakedAmount: Scalars['Float'];
};

export type StakingAsset = StakingAssetBase & {
  __typename?: 'StakingAsset';
  apy?: Maybe<Scalars['Float']>;
  currency: CryptoCurrency;
  epochLength?: Maybe<Scalars['Float']>;
  fiatCurrency: FiatCurrency;
  lockPeriod?: Maybe<Scalars['Float']>;
  minimumStaked?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  rewardDelay?: Maybe<Scalars['Float']>;
  type: StakingType;
};

export type StakingAssetBase = {
  apy?: Maybe<Scalars['Float']>;
  currency: CryptoCurrency;
  epochLength?: Maybe<Scalars['Float']>;
  fiatCurrency: FiatCurrency;
  lockPeriod?: Maybe<Scalars['Float']>;
  minimumStaked?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  rewardDelay?: Maybe<Scalars['Float']>;
  type: StakingType;
};

export type StakingAssetEarnings = {
  __typename?: 'StakingAssetEarnings';
  amount?: Maybe<Scalars['Float']>;
  fiatValue?: Maybe<Scalars['Float']>;
  yield?: Maybe<Scalars['Float']>;
};

export type StakingAssetStats = {
  __typename?: 'StakingAssetStats';
  active?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  earnings?: Maybe<StakingAssetEarnings>;
  fiatValue?: Maybe<Scalars['Float']>;
};

export type StakingStats = {
  __typename?: 'StakingStats';
  apy?: Maybe<Scalars['Float']>;
  fiatCurrency?: Maybe<FiatCurrency>;
  fiatValue?: Maybe<Scalars['Float']>;
};

export enum StakingType {
  Locked = 'LOCKED',
  Open = 'OPEN',
}

export type SubmitCalendarAnswerInput = {
  answerId: Scalars['String'];
  day: Scalars['Int'];
};

export type SubmitCalendarAnswerPayload = {
  __typename?: 'SubmitCalendarAnswerPayload';
  correct?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SubmitCryptoInput = {
  crypto?: InputMaybe<Array<Scalars['String']>>;
};

export type SubmitFeedbackInput = {
  key: FeedbackFeature;
  text?: InputMaybe<Scalars['String']>;
  value: FeedbackValue;
};

export type SubmitSurveyInput = {
  questions?: InputMaybe<Array<SurveyQuestion>>;
};

export type SuccessPayload = {
  __typename?: 'SuccessPayload';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SurveyAnswer = {
  answers: Array<InputMaybe<Scalars['String']>>;
  questionId: Scalars['Float'];
};

export type SurveyInput = {
  answers: Array<SurveyAnswer>;
};

export type SurveyQuestion = {
  question: Scalars['String'];
  text: Scalars['String'];
};

export enum Symbol {
  Aave = 'AAVE',
  Ada = 'ADA',
  Anc = 'ANC',
  Avax = 'AVAX',
  Bnb = 'BNB',
  Btc = 'BTC',
  Dai = 'DAI',
  Dkk = 'DKK',
  Doge = 'DOGE',
  Dot = 'DOT',
  Eth = 'ETH',
  Gst = 'GST',
  Link = 'LINK',
  Ltc = 'LTC',
  Mana = 'MANA',
  Matic = 'MATIC',
  Nok = 'NOK',
  Pol = 'POL',
  Sand = 'SAND',
  Sek = 'SEK',
  Sol = 'SOL',
  Total = 'TOTAL',
  Uni = 'UNI',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Xlm = 'XLM',
  Xrp = 'XRP',
}

export type System = {
  __typename?: 'System';
  health?: Maybe<Array<SystemStatus>>;
};

export type SystemStatus = {
  __typename?: 'SystemStatus';
  status: SystemStatusEnum;
  systemName: Scalars['String'];
};

export enum SystemStatusEnum {
  Down = 'down',
  Maintenance = 'maintenance',
  Up = 'up',
}

export type Tax = {
  __typename?: 'Tax';
  rebuilding?: Maybe<Scalars['Boolean']>;
  report?: Maybe<TaxReport>;
  reports?: Maybe<Array<TaxReport>>;
};

export type TaxReportArgs = {
  year: TaxYear;
};

export type TaxReport = {
  __typename?: 'TaxReport';
  data?: Maybe<Array<TaxReportData>>;
  fees?: Maybe<Scalars['String']>;
  missingPrices?: Maybe<Array<TaxTransactionMissingPrice>>;
  shouldRebuild?: Maybe<RebuildCause>;
  year?: Maybe<Scalars['String']>;
};

export type TaxReportData = {
  __typename?: 'TaxReportData';
  amount?: Maybe<Scalars['String']>;
  fortune?: Maybe<Scalars['String']>;
  income?: Maybe<Scalars['String']>;
  loss?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['String']>;
  type?: Maybe<Symbol>;
};

export type TaxTransactionMissingPrice = {
  __typename?: 'TaxTransactionMissingPrice';
  accountId: Scalars['String'];
  amount: Scalars['String'];
  currency: Symbol;
  fee?: Maybe<Scalars['String']>;
  fiatValue: Scalars['String'];
  id: Scalars['ID'];
  income?: Maybe<Scalars['Boolean']>;
  processed?: Maybe<TaxTransactionProcessed>;
  time: Scalars['DateTime'];
};

export enum TaxTransactionProcessed {
  Pending = 'pending',
  Processed = 'processed',
  Rebuilt = 'rebuilt',
}

export enum TaxYear {
  Y_2020 = 'Y_2020',
  Y_2021 = 'Y_2021',
  Y_2022 = 'Y_2022',
  Y_2023 = 'Y_2023',
  Y_2024 = 'Y_2024',
}

export type TinkAccount = {
  __typename?: 'TinkAccount';
  accountName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  balanceType?: Maybe<Scalars['String']>;
  bban?: Maybe<Scalars['String']>;
  currency?: Maybe<FiatCurrency>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type TinkInitiateAccountsInput = {
  providerId: Scalars['String'];
  redirectUrl: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  withSession?: InputMaybe<Scalars['Boolean']>;
};

export type TinkInitiateAccountsPayload = {
  __typename?: 'TinkInitiateAccountsPayload';
  sessionId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type TinkInitiatePaymentInput = {
  amount: Scalars['String'];
  id: Scalars['String'];
  redirectUrl?: InputMaybe<Scalars['String']>;
};

export type TinkInitiatePaymentPayload = {
  __typename?: 'TinkInitiatePaymentPayload';
  message?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export enum TinkStatusCode {
  Canceled = 'CANCELED',
  Error = 'ERROR',
  Ok = 'OK',
}

export type TinkUpdatePaymentInput = {
  errorCode?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  paymentId: Scalars['String'];
  status: TinkStatusCode;
};

export type TinkUpdatePaymentPayload = {
  __typename?: 'TinkUpdatePaymentPayload';
  message?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  status?: Maybe<TinkStatusCode>;
  success: Scalars['Boolean'];
};

export type ToggleFavoriteInput = {
  currency?: InputMaybe<CryptoCurrency>;
};

export type ToggleReadArticleInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TrackWidgetInput = {
  platform: Scalars['String'];
  type: Scalars['String'];
};

export type Trade = {
  __typename?: 'Trade';
  accountGroupId: Scalars['Int'];
  buyAmount?: Maybe<Scalars['String']>;
  buyCurrency?: Maybe<Symbol>;
  createdAt: Scalars['DateTime'];
  depositDetails?: Maybe<TradeDepositDetails>;
  feeAmount?: Maybe<Scalars['String']>;
  feeCurrency?: Maybe<Symbol>;
  id: Scalars['ID'];
  price?: Maybe<Scalars['String']>;
  priceCurrency?: Maybe<Symbol>;
  sellAmount?: Maybe<Scalars['String']>;
  sellCurrency?: Maybe<Symbol>;
  tradeDetails?: Maybe<Array<Trade>>;
  tradeType: TradeType;
};

export type TradeAccountGroup = AccountGroup & {
  __typename?: 'TradeAccountGroup';
  accounts?: Maybe<Array<Account>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  totalFiatValue: AccountTotalFiatValue;
  type: AccountType;
  uuid: Scalars['String'];
};

export type TradeDepositDetails = {
  __typename?: 'TradeDepositDetails';
  amount: Scalars['String'];
  depositType: Scalars['String'];
  fee: Scalars['String'];
  totalAmount: Scalars['String'];
};

export type TradeDetails = {
  __typename?: 'TradeDetails';
  buyAmount?: Maybe<Scalars['String']>;
  buyCurrency?: Maybe<Symbol>;
  createdAt: Scalars['DateTime'];
  feeAmount?: Maybe<Scalars['String']>;
  feeCurrency?: Maybe<Symbol>;
  id: Scalars['ID'];
  price?: Maybe<Scalars['String']>;
  priceCurrency?: Maybe<Symbol>;
  sellAmount?: Maybe<Scalars['String']>;
  sellCurrency?: Maybe<Symbol>;
  tradeType: TradeType;
};

export type TradeEntity = {
  __typename?: 'TradeEntity';
  buy_v2: Array<Trade>;
  results_v2: Array<Trade>;
  sell_v2: Array<Trade>;
};

export type TradeEntityBuy_V2Args = {
  accountGroupId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Symbol>;
};

export type TradeEntityResults_V2Args = {
  accountGroupId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Symbol>;
};

export type TradeEntitySell_V2Args = {
  accountGroupId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Symbol>;
};

export type TradeFilter = {
  tradeType?: InputMaybe<Array<TradeType>>;
};

export enum TradeType {
  Buy = 'Buy',
  Sell = 'Sell',
}

export type TradeVolume = {
  __typename?: 'TradeVolume';
  askCount?: Maybe<Scalars['Int']>;
  askVolume?: Maybe<Scalars['String']>;
  bidCount?: Maybe<Scalars['Int']>;
  bidVolume?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
};

export type TransactionMisc = {
  __typename?: 'TransactionMisc';
  accountGroupId: Scalars['Int'];
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Symbol;
  fiatValue?: Maybe<Scalars['String']>;
  fromAccountGroupId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  toAccountGroupId?: Maybe<Scalars['Int']>;
  type: TransactionsMiscTypes;
};

export enum TransactionsMiscTypes {
  AffiliateBonus = 'AffiliateBonus',
  AffiliateCommision = 'AffiliateCommision',
  Bonus = 'Bonus',
  ChristmasBonus = 'ChristmasBonus',
  DepositFee = 'DepositFee',
  Donation = 'Donation',
  FeebackBonus = 'FeebackBonus',
  InternalTransfer = 'InternalTransfer',
  Prize = 'Prize',
  Refund = 'Refund',
  Reversed = 'Reversed',
  Stake = 'Stake',
  StakingReward = 'StakingReward',
  Transfer = 'Transfer',
  Unstake = 'Unstake',
  Voucher = 'Voucher',
  WelcomeBonus = 'WelcomeBonus',
}

export type Transfer = {
  __typename?: 'Transfer';
  validateAddress: AddressValidity;
};

export type TransferValidateAddressArgs = {
  address: Scalars['String'];
  currency?: InputMaybe<Symbol>;
  unlistedCurrency?: InputMaybe<Scalars['String']>;
};

export type TransferDeposit = {
  __typename?: 'TransferDeposit';
  accountGroupId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  confirmations: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  currency: Symbol;
  depositType: DepositType;
  fee: Scalars['String'];
  fiatValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isFiat: Scalars['Boolean'];
  required: Scalars['Int'];
  status: DepositState;
  transactionId?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
};

export type TransferWithdraw = {
  __typename?: 'TransferWithdraw';
  accountGroupId: Scalars['Int'];
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Symbol;
  destination?: Maybe<Scalars['String']>;
  feeAmount?: Maybe<Scalars['String']>;
  feeCurrency?: Maybe<Symbol>;
  fiatValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isFiat: Scalars['Boolean'];
  state?: Maybe<WithdrawState>;
  txid?: Maybe<Scalars['String']>;
};

export type TranslationInput = {
  author: Scalars['String'];
  locale: Scalars['String'];
  originalText: Scalars['String'];
  proposedText: Scalars['String'];
};

export type TranslationPayload = {
  __typename?: 'TranslationPayload';
  author: Scalars['String'];
  originalText: Scalars['String'];
  proposedText: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type UnstakeTime = {
  __typename?: 'UnstakeTime';
  days: Scalars['Int'];
};

export enum UnstakingRequestStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Requested = 'requested',
}

export type UnstakingRequests = {
  __typename?: 'UnstakingRequests';
  amount?: Maybe<Scalars['Float']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<CryptoCurrency>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<UnstakingRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateAccountGroup = {
  accountGroupId: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateSavingAgreementDetail = {
  currency: Symbol;
  distribution: Scalars['Float'];
};

export type UpdateSavingAgreementDetails = {
  accountGroupId: Scalars['String'];
  details?: InputMaybe<Array<UpdateSavingAgreementDetail>>;
};

export type UpdateUserProfileInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nationality: Scalars['String'];
  postalArea: Scalars['String'];
};

export type UpdateUserProfileInput_V2 = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nationality: Scalars['String'];
  phone: Scalars['String'];
  postalArea: Scalars['String'];
};

export type UserDepositBankAccount = {
  __typename?: 'UserDepositBankAccount';
  accountNumber: Scalars['String'];
  currency: FiatCurrency;
};

export enum UserNeededAction {
  ConfirmNin = 'confirmNin',
  IdDocumentVerification = 'idDocumentVerification',
  IdentityVerification = 'identityVerification',
}

export type ValidateKycBankIdInput = {
  gdpr: GdprType;
  sessionId: Scalars['String'];
  sessionType: BankIdSessionType;
  state: BankIdCompleteStatus;
};

export type Versions = {
  __typename?: 'Versions';
  ios?: Maybe<Scalars['String']>;
  ota: Scalars['Int'];
  play?: Maybe<Scalars['String']>;
};

export type Voucher = {
  __typename?: 'Voucher';
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: CryptoCurrency;
  from: Scalars['String'];
  id: Scalars['ID'];
  to: Scalars['String'];
  voucherType: VoucherType;
};

export type VoucherEntity = {
  __typename?: 'VoucherEntity';
  voucher?: Maybe<Voucher>;
  vouchers?: Maybe<Array<Voucher>>;
};

export type VoucherEntityVoucherArgs = {
  id: Scalars['String'];
  redeemed?: InputMaybe<Scalars['Boolean']>;
};

export type VoucherEntityVouchersArgs = {
  redeemed?: InputMaybe<Scalars['Boolean']>;
};

export enum VoucherType {
  Christmas = 'christmas',
  Standard = 'standard',
  StandardGrey = 'standardGrey',
  StandardWhite = 'standardWhite',
}

export type WalletPayload = {
  __typename?: 'WalletPayload';
  address: Scalars['String'];
  destinationTag?: Maybe<Scalars['String']>;
};

export type Withdraw = {
  __typename?: 'Withdraw';
  amount: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Currency>;
  destination: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: WithdrawStatus;
  transactionId?: Maybe<Scalars['String']>;
};

export type WithdrawCryptoBankIdInput = {
  address: Scalars['String'];
  amount: Scalars['String'];
  currency: CryptoCurrency;
  destinationTag?: InputMaybe<Scalars['String']>;
  fiatAmount: Scalars['Float'];
  priceIndex: Scalars['Float'];
  sessionId: Scalars['String'];
  sessionType: BankIdSessionType;
  status: BankIdCompleteStatus;
};

export type WithdrawEntity = {
  __typename?: 'WithdrawEntity';
  withdrawLimit: WithdrawUsageAndLimit;
};

export type WithdrawEntityWithdrawLimitArgs = {
  currency?: InputMaybe<Symbol>;
};

export type WithdrawFiatBankIdInput = {
  amount: Scalars['String'];
  bankAccountId: Scalars['String'];
  currency: FiatCurrency;
  sessionId: Scalars['String'];
  sessionType: BankIdSessionType;
  status: BankIdCompleteStatus;
};

export type WithdrawFilter = {
  withdrawState?: InputMaybe<Array<WithdrawState>>;
};

export type WithdrawLightning = {
  __typename?: 'WithdrawLightning';
  invoice?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type WithdrawLimit = {
  __typename?: 'WithdrawLimit';
  fee: Scalars['String'];
  limit: Scalars['String'];
  minimumAmount: Scalars['String'];
  securityLevel: Scalars['Int'];
};

export type WithdrawMinimumLimit = {
  __typename?: 'WithdrawMinimumLimit';
  limit: Scalars['Float'];
  source: Scalars['Float'];
};

export enum WithdrawState {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Pending = 'pending',
  Processing = 'processing',
  Requested = 'requested',
}

export enum WithdrawStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Error = 'error',
  Processing = 'processing',
  Requested = 'requested',
}

export type WithdrawUsageAndLimit = {
  __typename?: 'WithdrawUsageAndLimit';
  currency: Symbol;
  fee: Scalars['Float'];
  index: Scalars['Float'];
  limit: Scalars['Float'];
  locked: Scalars['Boolean'];
  lockedAmount: Scalars['Float'];
  maxLimit: Scalars['Float'];
  minLimit: WithdrawMinimumLimit;
  remaining: Scalars['Float'];
  usage: Scalars['Float'];
};

export type WithdrawUsageAndLimitMinLimitArgs = {
  currency?: InputMaybe<Symbol>;
};

export type WithdrawV2 = {
  __typename?: 'WithdrawV2';
  amount: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  destination: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: WithdrawStatus;
  transactionId?: Maybe<Scalars['String']>;
};

export type YieldCurrency = {
  __typename?: 'YieldCurrency';
  cryptoBalance?: Maybe<Scalars['String']>;
  cryptoCurrency: CryptoCurrency;
  fiatAmount: Scalars['String'];
  fiatBalance: Scalars['String'];
  percent: Scalars['String'];
};

export type YieldData = {
  __typename?: 'YieldData';
  data?: Maybe<Array<YieldDataPoint>>;
  fiatCurrency: FiatCurrency;
  restrictedMode?: Maybe<Scalars['Boolean']>;
};

export type YieldDataPoint = {
  __typename?: 'YieldDataPoint';
  currencies?: Maybe<Array<YieldCurrency>>;
  date: Scalars['DateTime'];
  total: YieldTotal;
};

export type YieldDataV2 = {
  __typename?: 'YieldDataV2';
  currencies?: Maybe<Array<PnlBalanceCurrency>>;
  data?: Maybe<Array<YieldDataPoint>>;
  fiatCurrency: FiatCurrency;
  restrictedMode?: Maybe<Scalars['Boolean']>;
  total: YieldTotal;
};

export type YieldTotal = {
  __typename?: 'YieldTotal';
  fiatAmount: Scalars['String'];
  fiatBalance: Scalars['String'];
  percent: Scalars['String'];
  totalBalanceWithFiat: Scalars['String'];
};

export type AccountFragmentFragment = {
  __typename?: 'Account';
  id: string;
  currency: Symbol;
  currencyName: string;
  balance: string;
  hold: string;
  available: string;
  fiatValues: Array<{
    __typename?: 'AccountFiatValues';
    currency: string;
    balance: string;
    hold: string;
    available: string;
  }>;
  fiatValue: {
    __typename?: 'AccountFiatValue';
    currency: FiatCurrency;
    balance: string;
    hold: string;
    available: string;
  };
};

export type KycFormFragmentFragment = {
  __typename?: 'KycFormResponse';
  id: number;
  title: string;
  prologue?: string | null;
  hasUpload?: boolean | null;
  completedOn?: { __typename?: 'SentOn'; seconds?: number | null; nanos?: number | null } | null;
  questions: Array<{
    __typename?: 'KycQuestion';
    id: number;
    order: number;
    isFreeText: boolean;
    answered: Array<string | null>;
    translationKey: string;
    title: string;
    required: boolean;
    answers: Array<{
      __typename?: 'KycAnswer';
      id?: number | null;
      answerText?: string | null;
      translationKey?: string | null;
      subsequentquestionAnswerId?: number | null;
    }>;
  }>;
};

export type SavingAgreementFragmentFragment = {
  __typename?: 'SavingAgreement';
  id: string;
  accountGroupId: string;
  amount?: string | null;
  recurring?: number | null;
  savingDetails?: Array<{
    __typename?: 'SavingAgreementDetail';
    id: string;
    currency: Symbol;
    distribution: number;
  }> | null;
};

export type TradeFragmentFragment = {
  __typename?: 'Trade';
  id: string;
  tradeType: TradeType;
  createdAt: string;
  price?: string | null;
  priceCurrency?: Symbol | null;
  buyAmount?: string | null;
  buyCurrency?: Symbol | null;
  sellAmount?: string | null;
  sellCurrency?: Symbol | null;
  feeAmount?: string | null;
  feeCurrency?: Symbol | null;
  accountGroupId: number;
  depositDetails?: {
    __typename?: 'TradeDepositDetails';
    depositType: string;
    amount: string;
    fee: string;
    totalAmount: string;
  } | null;
};

export type TransactionMiscFragmentFragment = {
  __typename?: 'TransactionMisc';
  id: string;
  accountGroupId: number;
  createdAt: string;
  amount: string;
  type: TransactionsMiscTypes;
  currency: Symbol;
  fromAccountGroupId?: number | null;
  toAccountGroupId?: number | null;
  fiatValue?: string | null;
};

export type TransferDepositFragmentFragment = {
  __typename?: 'TransferDeposit';
  id: string;
  amount: string;
  currency: Symbol;
  createdAt: string;
  address?: string | null;
  status: DepositState;
  confirmations: number;
  required: number;
  isFiat: boolean;
  txid?: string | null;
  depositType: DepositType;
  accountGroupId: number;
  transactionId?: string | null;
  fee: string;
  fiatValue?: string | null;
};

export type TransferWithdrawFragmentFragment = {
  __typename?: 'TransferWithdraw';
  id: string;
  amount: string;
  currency: Symbol;
  createdAt: string;
  state?: WithdrawState | null;
  destination?: string | null;
  txid?: string | null;
  feeAmount?: string | null;
  feeCurrency?: Symbol | null;
  isFiat: boolean;
  fiatValue?: string | null;
  accountGroupId: number;
};

export type UserFragmentFragment = {
  __typename?: 'Profile';
  id: string;
  address?: string | null;
  city?: string | null;
  country?: string | null;
  email: string;
  type: string;
  tag: string;
  uuid?: string | null;
  postalArea?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  twoFactor: boolean;
  nationality?: string | null;
  securityLevel: number;
  hasVippsLinked: boolean;
  features: Array<string>;
  dateOfBirth?: string | null;
  transactionFee?: string | null;
  affiliateCode?: string | null;
  onboardingStatus?: OnboardingStatusEnum | null;
  hasVerifiedEmail: boolean;
  mustVerifyEmail: boolean;
  createdAt?: string | null;
  actions?: Array<UserNeededAction | null> | null;
  corporate: { __typename?: 'Corporate'; name?: string | null };
  fiatMarket: { __typename?: 'FiatMarket'; id?: string | null; symbol?: FiatCurrency | null };
};

export type TwoFaMutationVariables = Exact<{
  input: EnableOtpInput;
}>;

export type TwoFaMutation = {
  __typename?: 'Mutation';
  toggle2fa: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type ActivateStakingMutationVariables = Exact<{
  input: ActivateStakingInput;
}>;

export type ActivateStakingMutation = {
  __typename?: 'Mutation';
  activateStaking: { __typename?: 'SuccessPayload'; success: boolean };
};

export type AddBankAccountMutationVariables = Exact<{
  input: BankAccountInput;
}>;

export type AddBankAccountMutation = {
  __typename?: 'Mutation';
  createBankAccount: {
    __typename?: 'BankAccount';
    id: string;
    iban?: string | null;
    swiftbic?: string | null;
    displayName?: string | null;
    accountNumber?: string | null;
    routingNumber?: string | null;
  };
};

export type AddContactMutationVariables = Exact<{
  input: AddContactInput;
}>;

export type AddContactMutation = {
  __typename?: 'Mutation';
  addContact: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type AdTractionConversionMutationVariables = Exact<{
  input: AdTractionInput;
}>;

export type AdTractionConversionMutation = {
  __typename?: 'Mutation';
  adTractionConversion: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type AdyenSubmitPaymentMutationVariables = Exact<{
  input: AdyenSubmitPaymentInput;
}>;

export type AdyenSubmitPaymentMutation = {
  __typename?: 'Mutation';
  adyenSubmitPayment: {
    __typename?: 'AdyenSubmitPaymentPayload';
    success: boolean;
    url?: string | null;
    message?: string | null;
  };
};

export type AdyenSubmitPaymentDetailsMutationVariables = Exact<{
  input: AdyenSubmitPaymentDetailsInput;
}>;

export type AdyenSubmitPaymentDetailsMutation = {
  __typename?: 'Mutation';
  adyenSubmitPaymentDetails: {
    __typename?: 'AdyenSubmitPaymentDetailsPayload';
    success: boolean;
    resultCode?: AdyenPaymentStatus | null;
  };
};

export type CalculatePaymentFeeMutationVariables = Exact<{
  input: CalculatePaymentFeeInput;
}>;

export type CalculatePaymentFeeMutation = {
  __typename?: 'Mutation';
  calculatePaymentFee: {
    __typename?: 'CalculatePaymentFeePayload';
    success: boolean;
    message?: string | null;
    amount?: string | null;
    currency?: FiatCurrency | null;
    feeAmount?: string | null;
    feePercent?: string | null;
    totalAmount?: string | null;
  };
};

export type CancelLightningInvoicesMutationVariables = Exact<{ [key: string]: never }>;

export type CancelLightningInvoicesMutation = {
  __typename?: 'Mutation';
  cancelLightningInvoices: string;
};

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderInput;
}>;

export type CancelOrderMutation = {
  __typename?: 'Mutation';
  cancelOrder: { __typename?: 'SuccessPayload'; success: boolean };
};

export type CancelWithdrawMutationVariables = Exact<{
  input: CancelWithdrawInput;
}>;

export type CancelWithdrawMutation = {
  __typename?: 'Mutation';
  cancelWithdraw: {
    __typename?: 'CancelWithdrawPayload';
    withdraw?: { __typename?: 'Withdraw'; id: string } | null;
  };
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword: { __typename?: 'SuccessPayload'; success: boolean };
};

export type ChangeUnverifiedEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ChangeUnverifiedEmailMutation = {
  __typename?: 'Mutation';
  changeUnverifiedEmail: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type CheckReferralCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type CheckReferralCodeMutation = {
  __typename?: 'Mutation';
  checkReferralCode?: {
    __typename?: 'AffiliateOwnerPayload';
    name?: string | null;
    validTo?: string | null;
  } | null;
};

export type ClaimRewardMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ClaimRewardMutation = {
  __typename?: 'Mutation';
  claimReward: {
    __typename?: 'ClaimRewardPayload';
    uuid: string;
    success: boolean;
    message?: string | null;
  };
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;

export type CreateAccountMutation = {
  __typename?: 'Mutation';
  createAccount: { __typename?: 'CreateAccountPayload'; success: boolean; msg?: string | null };
};

export type CreateAccountGroupMutationVariables = Exact<{
  input: CreateAccountGroup;
}>;

export type CreateAccountGroupMutation = {
  __typename?: 'Mutation';
  createAccountGroup: {
    __typename?: 'CreateAccountGroupPayload';
    success: boolean;
    message?: string | null;
    accountGroupId?: string | null;
  };
};

export type CreateLightningInvoiceMutationVariables = Exact<{
  input?: InputMaybe<CreateLightningInvoiceInput>;
}>;

export type CreateLightningInvoiceMutation = {
  __typename?: 'Mutation';
  createLightningInvoice?: {
    __typename?: 'LightningInvoice';
    address: string;
    destinationTag?: string | null;
  } | null;
};

export type CreateSavingsAgreementMutationVariables = Exact<{
  input: CreateSavingAgreement;
}>;

export type CreateSavingsAgreementMutation = {
  __typename?: 'Mutation';
  createSavingAgreement: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type CreateVoucherBankIdMutationVariables = Exact<{
  input: CreateVoucherBankIdInput;
}>;

export type CreateVoucherBankIdMutation = {
  __typename?: 'Mutation';
  createVoucherBankId: {
    __typename?: 'CreateVoucherBankIdPayload';
    success: boolean;
    message?: string | null;
    id?: string | null;
  };
};

export type DeativateStakingMutationVariables = Exact<{
  input: DeactivateStakingInput;
}>;

export type DeativateStakingMutation = {
  __typename?: 'Mutation';
  deactivateStaking: { __typename?: 'SuccessPayload'; success: boolean };
};

export type DeleteApiKeyMutationVariables = Exact<{
  apiKey: Scalars['String'];
}>;

export type DeleteApiKeyMutation = { __typename?: 'Mutation'; deleteApiKey: boolean };

export type DeleteContactMutationVariables = Exact<{
  input: DeleteContactInput;
}>;

export type DeleteContactMutation = {
  __typename?: 'Mutation';
  deleteContact: { __typename?: 'SuccessPayload'; success: boolean };
};

export type DeleteProfilePictureMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteProfilePictureMutation = {
  __typename?: 'Mutation';
  deleteProfilePicture: { __typename?: 'SuccessPayload'; success: boolean };
};

export type DonateMutationVariables = Exact<{
  input: DonateInput;
}>;

export type DonateMutation = {
  __typename?: 'Mutation';
  donate: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type EditPurchasePriceMutationVariables = Exact<{
  input: EditPurchasePriceInput;
}>;

export type EditPurchasePriceMutation = {
  __typename?: 'Mutation';
  editPurchasePrice: {
    __typename?: 'EditPurchasePricePayload';
    success: boolean;
    message?: string | null;
  };
};

export type GenerateApiKeyMutationVariables = Exact<{
  input: KeygenInput;
}>;

export type GenerateApiKeyMutation = {
  __typename?: 'Mutation';
  generateApiKey: {
    __typename?: 'KeygenPayload';
    apiKey: string;
    clientId: string;
    secretKey: string;
  };
};

export type GenerateBankIdSessionMutationVariables = Exact<{
  input: GenerateBankIdSessionInput;
}>;

export type GenerateBankIdSessionMutation = {
  __typename?: 'Mutation';
  generateBankIdSession: {
    __typename?: 'BankIdSessionPayload';
    url?: string | null;
    success: boolean;
    message?: string | null;
    id?: string | null;
  };
};

export type HeyLinkConversionMutationVariables = Exact<{
  input: HeyLinkInput;
}>;

export type HeyLinkConversionMutation = {
  __typename?: 'Mutation';
  heyLinkConversion: { __typename?: 'SuccessPayload'; success: boolean };
};

export type InternalTransferMutationVariables = Exact<{
  input: InternAccountTransfer;
}>;

export type InternalTransferMutation = {
  __typename?: 'Mutation';
  internAccountTransfer: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type InvalidateBankIdSessionMutationVariables = Exact<{
  input: InvalidateBankIdSessionInput;
}>;

export type InvalidateBankIdSessionMutation = {
  __typename?: 'Mutation';
  invalidateBankIdSession: { __typename?: 'SuccessPayload'; success: boolean };
};

export type KycMultipleAnswersMutationVariables = Exact<{
  input: KycMultipleInput;
}>;

export type KycMultipleAnswersMutation = {
  __typename?: 'Mutation';
  sendMultipleAnswer: {
    __typename?: 'KycFormResponse';
    id: number;
    title: string;
    prologue?: string | null;
    hasUpload?: boolean | null;
    completedOn?: { __typename?: 'SentOn'; seconds?: number | null; nanos?: number | null } | null;
    questions: Array<{
      __typename?: 'KycQuestion';
      id: number;
      order: number;
      isFreeText: boolean;
      answered: Array<string | null>;
      translationKey: string;
      title: string;
      required: boolean;
      answers: Array<{
        __typename?: 'KycAnswer';
        id?: number | null;
        answerText?: string | null;
        translationKey?: string | null;
        subsequentquestionAnswerId?: number | null;
      }>;
    }>;
  };
};

export type KycSingleAnswerMutationVariables = Exact<{
  input: KycAnswerInput;
}>;

export type KycSingleAnswerMutation = {
  __typename?: 'Mutation';
  submitKYCAnswer: {
    __typename?: 'KycFormResponse';
    id: number;
    title: string;
    prologue?: string | null;
    hasUpload?: boolean | null;
    completedOn?: { __typename?: 'SentOn'; seconds?: number | null; nanos?: number | null } | null;
    questions: Array<{
      __typename?: 'KycQuestion';
      id: number;
      order: number;
      isFreeText: boolean;
      answered: Array<string | null>;
      translationKey: string;
      title: string;
      required: boolean;
      answers: Array<{
        __typename?: 'KycAnswer';
        id?: number | null;
        answerText?: string | null;
        translationKey?: string | null;
        subsequentquestionAnswerId?: number | null;
      }>;
    }>;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type PasswordResetCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type PasswordResetCodeMutation = {
  __typename?: 'Mutation';
  requestPasswordResetCode: {
    __typename?: 'PasswordResetCodePayload';
    code?: string | null;
    email?: string | null;
    success: boolean;
    message?: string | null;
  };
};

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RequestPasswordResetMutation = {
  __typename?: 'Mutation';
  requestPasswordReset: { __typename?: 'SuccessPayload'; success: boolean };
};

export type PlaceMarketOrderMutationVariables = Exact<{
  input: PlaceOrderInput;
}>;

export type PlaceMarketOrderMutation = {
  __typename?: 'Mutation';
  placeMarketOrder?: {
    __typename?: 'MarketOrderResponse';
    errorMsg?: string | null;
    result?: {
      __typename?: 'MarketOrderPayload';
      fee: string;
      exchangeRate: string;
      assetMatched: string;
      fiatMatched: string;
    } | null;
  } | null;
};

export type PlaceOrderMutationVariables = Exact<{
  input: PlaceOrderInput;
}>;

export type PlaceOrderMutation = {
  __typename?: 'Mutation';
  placeOrder: {
    __typename?: 'OrderResponse';
    ok: boolean;
    error?: { __typename?: 'Error'; path: string; message: string } | null;
  };
};

export type RebuildTaxMutationVariables = Exact<{ [key: string]: never }>;

export type RebuildTaxMutation = {
  __typename?: 'Mutation';
  rebuildTax: { __typename?: 'RebuildTaxPayload'; success: boolean; message?: string | null };
};

export type RedeemVoucherMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RedeemVoucherMutation = {
  __typename?: 'Mutation';
  redeemVoucher: {
    __typename?: 'RedeemVoucherPayload';
    amount?: string | null;
    currency?: CryptoCurrency | null;
    error?: string | null;
  };
};

export type RegisterCustomerIoTokenMutationVariables = Exact<{
  input: CustomerIoRegisterInput;
}>;

export type RegisterCustomerIoTokenMutation = {
  __typename?: 'Mutation';
  registerCustomerIoPushToken: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type RegisterPushTokenMutationVariables = Exact<{
  input: PushNotificationInput;
}>;

export type RegisterPushTokenMutation = {
  __typename?: 'Mutation';
  registerPushToken: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type RemoveBankAccountMutationVariables = Exact<{
  input: RemoveBankAccountInput;
}>;

export type RemoveBankAccountMutation = {
  __typename?: 'Mutation';
  removeBankAccount: { __typename?: 'SuccessPayload'; success: boolean };
};

export type RemoveOtpMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type RemoveOtpMutation = {
  __typename?: 'Mutation';
  removeOtp: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type RequestLightningWithdrawMutationVariables = Exact<{
  input: RequestWithdrawLightningInput;
}>;

export type RequestLightningWithdrawMutation = {
  __typename?: 'Mutation';
  requestWithdrawLightning: {
    __typename?: 'WithdrawLightning';
    url?: string | null;
    invoice?: string | null;
    state?: string | null;
  };
};

export type ResendVerificationCodeMutationVariables = Exact<{ [key: string]: never }>;

export type ResendVerificationCodeMutation = {
  __typename?: 'Mutation';
  resendVerificationCode: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type SetAirdropAddressMutationVariables = Exact<{
  input: AirdropAddressInput;
}>;

export type SetAirdropAddressMutation = {
  __typename?: 'Mutation';
  setAirdropAddress: { __typename?: 'SuccessPayload'; success: boolean };
};

export type SetMetadataMutationVariables = Exact<{
  input: MetadataInput;
}>;

export type SetMetadataMutation = {
  __typename?: 'Mutation';
  setMetadata: { __typename?: 'SuccessPayload'; success: boolean };
};

export type SubmitCalendarAnswerMutationVariables = Exact<{
  input: SubmitCalendarAnswerInput;
}>;

export type SubmitCalendarAnswerMutation = {
  __typename?: 'Mutation';
  submitCalendarAnswer: {
    __typename?: 'SubmitCalendarAnswerPayload';
    success: boolean;
    correct?: boolean | null;
  };
};

export type SubmitCryptoMutationVariables = Exact<{
  input: SubmitCryptoInput;
}>;

export type SubmitCryptoMutation = {
  __typename?: 'Mutation';
  submitCrypto: { __typename?: 'SuccessPayload'; success: boolean };
};

export type SubmitFeedbackMutationVariables = Exact<{
  input: SubmitFeedbackInput;
}>;

export type SubmitFeedbackMutation = {
  __typename?: 'Mutation';
  submitFeedback: { __typename?: 'SuccessPayload'; success: boolean };
};

export type SubmitSurveyMutationVariables = Exact<{
  input: SubmitSurveyInput;
}>;

export type SubmitSurveyMutation = {
  __typename?: 'Mutation';
  submitSurvey: { __typename?: 'SuccessPayload'; success: boolean };
};

export type SubmitSurveyFirestoreMutationVariables = Exact<{
  input: SurveyInput;
}>;

export type SubmitSurveyFirestoreMutation = {
  __typename?: 'Mutation';
  submitSurveyFirestore: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type SubmitTranslationMutationVariables = Exact<{
  input: TranslationInput;
}>;

export type SubmitTranslationMutation = {
  __typename?: 'Mutation';
  submitTranslation: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type SubmitUserNinMutationVariables = Exact<{
  nin: Scalars['String'];
}>;

export type SubmitUserNinMutation = {
  __typename?: 'Mutation';
  submitUserNin: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type ValidateBankIdSessionMutationVariables = Exact<{
  input: InvalidateBankIdSessionInput;
}>;

export type ValidateBankIdSessionMutation = {
  __typename?: 'Mutation';
  validateBankIdSession: { __typename?: 'SuccessPayload'; success: boolean };
};

export type TinkInitiateAccountsMutationVariables = Exact<{
  input: TinkInitiateAccountsInput;
}>;

export type TinkInitiateAccountsMutation = {
  __typename?: 'Mutation';
  tinkInitiateAccounts: {
    __typename?: 'TinkInitiateAccountsPayload';
    success?: boolean | null;
    url?: string | null;
    sessionId?: string | null;
  };
};

export type TinkInitiatePaymentMutationVariables = Exact<{
  input: TinkInitiatePaymentInput;
}>;

export type TinkInitiatePaymentMutation = {
  __typename?: 'Mutation';
  tinkInitiatePayment: {
    __typename?: 'TinkInitiatePaymentPayload';
    success: boolean;
    message?: string | null;
    url?: string | null;
    paymentId?: string | null;
  };
};

export type TinkUpdatePaymentMutationVariables = Exact<{
  input: TinkUpdatePaymentInput;
}>;

export type TinkUpdatePaymentMutation = {
  __typename?: 'Mutation';
  tinkUpdatePayment: {
    __typename?: 'TinkUpdatePaymentPayload';
    success: boolean;
    message?: string | null;
    status?: TinkStatusCode | null;
    paymentId?: string | null;
  };
};

export type ToggleArticleReadMutationVariables = Exact<{
  input: ToggleReadArticleInput;
}>;

export type ToggleArticleReadMutation = {
  __typename?: 'Mutation';
  toggleReadArticle: { __typename?: 'SuccessPayload'; success: boolean };
};

export type ToggleFavoriteMutationVariables = Exact<{
  input: ToggleFavoriteInput;
}>;

export type ToggleFavoriteMutation = {
  __typename?: 'Mutation';
  toggleFavorite: { __typename?: 'SuccessPayload'; success: boolean };
};

export type UnRegisterPushTokenMutationVariables = Exact<{
  input: PushNotificationInput;
}>;

export type UnRegisterPushTokenMutation = {
  __typename?: 'Mutation';
  unRegisterPushToken: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type UpdateAccountGroupMutationVariables = Exact<{
  input: UpdateAccountGroup;
}>;

export type UpdateAccountGroupMutation = {
  __typename?: 'Mutation';
  updateAccountGroup: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type ApiKeyMutationVariables = Exact<{
  apiKey: ApiKeyInput;
}>;

export type ApiKeyMutation = { __typename?: 'Mutation'; updateApiKey: boolean };

export type AlterConsentMutationVariables = Exact<{
  input: ConsentInput;
}>;

export type AlterConsentMutation = { __typename?: 'Mutation'; alterConsent: boolean };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput_V2;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateUserProfile_v2: {
    __typename?: 'Profile';
    id: string;
    address?: string | null;
    city?: string | null;
    country?: string | null;
    nationality?: string | null;
    email: string;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    postalArea?: string | null;
    securityLevel: number;
    twoFactor: boolean;
  };
};

export type UpdatePushPreferenceMutationVariables = Exact<{
  input: PushNotificationPreferenceInput;
}>;

export type UpdatePushPreferenceMutation = {
  __typename?: 'Mutation';
  updatePushPreference: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type UpdateSavingAgreementMutationVariables = Exact<{
  input: CreateSavingAgreement;
}>;

export type UpdateSavingAgreementMutation = {
  __typename?: 'Mutation';
  updateSavingAgreement: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type UpdateSavingAgreementDetailsMutationVariables = Exact<{
  input: UpdateSavingAgreementDetails;
}>;

export type UpdateSavingAgreementDetailsMutation = {
  __typename?: 'Mutation';
  updateSavingAgreementDetails: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type ValidateKycBankIdMutationVariables = Exact<{
  input: ValidateKycBankIdInput;
}>;

export type ValidateKycBankIdMutation = {
  __typename?: 'Mutation';
  validateKycBankId: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type VerifyEmailCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type VerifyEmailCodeMutation = {
  __typename?: 'Mutation';
  verifyEmailCode: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type VerifyUserMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type VerifyUserMutation = {
  __typename?: 'Mutation';
  verifyUser: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type WithdrawCryptoBankIdMutationVariables = Exact<{
  input: WithdrawCryptoBankIdInput;
}>;

export type WithdrawCryptoBankIdMutation = {
  __typename?: 'Mutation';
  withdrawCryptoBankId: {
    __typename?: 'SuccessPayload';
    success: boolean;
    message?: string | null;
  };
};

export type WithdrawFiatBankIdMutationVariables = Exact<{
  input: WithdrawFiatBankIdInput;
}>;

export type WithdrawFiatBankIdMutation = {
  __typename?: 'Mutation';
  withdrawFiatBankId: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type WithdrawKycConsentMutationVariables = Exact<{ [key: string]: never }>;

export type WithdrawKycConsentMutation = {
  __typename?: 'Mutation';
  withdrawKycConsent: { __typename?: 'SuccessPayload'; success: boolean; message?: string | null };
};

export type AccountGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AccountGroupQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'AccountEntity';
    accountGroup?:
      | {
          __typename?: 'SavingAccountGroup';
          id: string;
          uuid: string;
          name: string;
          type: AccountType;
          savingsAgreement?: {
            __typename?: 'SavingAgreement';
            id: string;
            accountGroupId: string;
            amount?: string | null;
            recurring?: number | null;
            savingDetails?: Array<{
              __typename?: 'SavingAgreementDetail';
              id: string;
              currency: Symbol;
              distribution: number;
            }> | null;
          } | null;
          totalFiatValue: {
            __typename?: 'AccountTotalFiatValue';
            value: string;
            valueWithFiat: string;
            currency: FiatCurrency;
          };
          accounts?: Array<{
            __typename?: 'Account';
            id: string;
            currency: Symbol;
            currencyName: string;
            balance: string;
            hold: string;
            available: string;
            fiatValues: Array<{
              __typename?: 'AccountFiatValues';
              currency: string;
              balance: string;
              hold: string;
              available: string;
            }>;
            fiatValue: {
              __typename?: 'AccountFiatValue';
              currency: FiatCurrency;
              balance: string;
              hold: string;
              available: string;
            };
          }> | null;
        }
      | {
          __typename?: 'StakingAccountGroup';
          id: string;
          uuid: string;
          name: string;
          type: AccountType;
          totalFiatValue: {
            __typename?: 'AccountTotalFiatValue';
            value: string;
            valueWithFiat: string;
            currency: FiatCurrency;
          };
          accounts?: Array<{
            __typename?: 'Account';
            id: string;
            currency: Symbol;
            currencyName: string;
            balance: string;
            hold: string;
            available: string;
            fiatValues: Array<{
              __typename?: 'AccountFiatValues';
              currency: string;
              balance: string;
              hold: string;
              available: string;
            }>;
            fiatValue: {
              __typename?: 'AccountFiatValue';
              currency: FiatCurrency;
              balance: string;
              hold: string;
              available: string;
            };
          }> | null;
        }
      | {
          __typename?: 'TradeAccountGroup';
          id: string;
          uuid: string;
          name: string;
          type: AccountType;
          totalFiatValue: {
            __typename?: 'AccountTotalFiatValue';
            value: string;
            valueWithFiat: string;
            currency: FiatCurrency;
          };
          accounts?: Array<{
            __typename?: 'Account';
            id: string;
            currency: Symbol;
            currencyName: string;
            balance: string;
            hold: string;
            available: string;
            fiatValues: Array<{
              __typename?: 'AccountFiatValues';
              currency: string;
              balance: string;
              hold: string;
              available: string;
            }>;
            fiatValue: {
              __typename?: 'AccountFiatValue';
              currency: FiatCurrency;
              balance: string;
              hold: string;
              available: string;
            };
          }> | null;
        }
      | null;
  };
};

export type AccountsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'AccountEntity';
    accounts: Array<
      | {
          __typename?: 'SavingAccountGroup';
          id: string;
          uuid: string;
          name: string;
          type: AccountType;
          savingsAgreement?: {
            __typename?: 'SavingAgreement';
            id: string;
            accountGroupId: string;
            amount?: string | null;
            recurring?: number | null;
            savingDetails?: Array<{
              __typename?: 'SavingAgreementDetail';
              id: string;
              currency: Symbol;
              distribution: number;
            }> | null;
          } | null;
          totalFiatValue: {
            __typename?: 'AccountTotalFiatValue';
            currency: FiatCurrency;
            value: string;
            valueWithFiat: string;
          };
          accounts?: Array<{
            __typename?: 'Account';
            id: string;
            currency: Symbol;
            currencyName: string;
            balance: string;
            hold: string;
            available: string;
            fiatValue: {
              __typename?: 'AccountFiatValue';
              currency: FiatCurrency;
              balance: string;
              hold: string;
              available: string;
            };
            fiatValues: Array<{
              __typename?: 'AccountFiatValues';
              currency: string;
              balance: string;
              hold: string;
              available: string;
            }>;
          }> | null;
        }
      | {
          __typename?: 'StakingAccountGroup';
          id: string;
          uuid: string;
          name: string;
          type: AccountType;
          totalFiatValue: {
            __typename?: 'AccountTotalFiatValue';
            currency: FiatCurrency;
            value: string;
            valueWithFiat: string;
          };
          accounts?: Array<{
            __typename?: 'Account';
            id: string;
            currency: Symbol;
            currencyName: string;
            balance: string;
            hold: string;
            available: string;
            fiatValue: {
              __typename?: 'AccountFiatValue';
              currency: FiatCurrency;
              balance: string;
              hold: string;
              available: string;
            };
            fiatValues: Array<{
              __typename?: 'AccountFiatValues';
              currency: string;
              balance: string;
              hold: string;
              available: string;
            }>;
          }> | null;
        }
      | {
          __typename?: 'TradeAccountGroup';
          id: string;
          uuid: string;
          name: string;
          type: AccountType;
          totalFiatValue: {
            __typename?: 'AccountTotalFiatValue';
            currency: FiatCurrency;
            value: string;
            valueWithFiat: string;
          };
          accounts?: Array<{
            __typename?: 'Account';
            id: string;
            currency: Symbol;
            currencyName: string;
            balance: string;
            hold: string;
            available: string;
            fiatValue: {
              __typename?: 'AccountFiatValue';
              currency: FiatCurrency;
              balance: string;
              hold: string;
              available: string;
            };
            fiatValues: Array<{
              __typename?: 'AccountFiatValues';
              currency: string;
              balance: string;
              hold: string;
              available: string;
            }>;
          }> | null;
        }
    >;
  };
};

export type ActiveLightningInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveLightningInvoicesQuery = {
  __typename?: 'Query';
  activeLightningInvoices: Array<string>;
};

export type ActivitiesQueryVariables = Exact<{ [key: string]: never }>;

export type ActivitiesQuery = {
  __typename?: 'Query';
  activities: Array<{
    __typename?: 'Activity';
    id: string;
    type: ActivityType;
    created: string;
    details: {
      __typename?: 'ActivityDetails';
      detailsId?: string | null;
      amount?: string | null;
      aon?: boolean | null;
      market?: string | null;
      price?: string | null;
      type?: string | null;
      original?: string | null;
      total?: string | null;
      from?: string | null;
      currency?: string | null;
      reference?: string | null;
      accountNumber?: string | null;
      iban?: string | null;
      to?: string | null;
    };
  }>;
};

export type ApiKeysQueryVariables = Exact<{ [key: string]: never }>;

export type ApiKeysQuery = {
  __typename?: 'Query';
  apiKeys: Array<{
    __typename?: 'ApiKey';
    apiKey: string;
    write: boolean;
    read: boolean;
    withdraw: boolean;
  }>;
};

export type AppVersionsQueryVariables = Exact<{ [key: string]: never }>;

export type AppVersionsQuery = {
  __typename?: 'Query';
  appVersion?: {
    __typename?: 'AppVersion';
    versions?: {
      __typename?: 'Versions';
      ios?: string | null;
      play?: string | null;
      ota: number;
    } | null;
  } | null;
};

export type AvailableCryptoCurrenciesQueryVariables = Exact<{
  accountGroupId?: InputMaybe<Scalars['String']>;
}>;

export type AvailableCryptoCurrenciesQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'AccountEntity';
    availableCryptoCurrencies?: Array<{
      __typename?: 'CryptoCurrencyPayload';
      id: CryptoCurrency;
      name: string;
    }> | null;
  };
};

export type AydenPaymentStatusQueryVariables = Exact<{
  merchantReference: Scalars['String'];
}>;

export type AydenPaymentStatusQuery = {
  __typename?: 'Query';
  payment: { __typename?: 'Payment'; aydenPaymentStatus?: AdyenPaymentStatus | null };
};

export type BankAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type BankAccountsQuery = {
  __typename?: 'Query';
  bankAccounts: Array<{
    __typename?: 'BankAccount';
    id: string;
    iban?: string | null;
    swiftbic?: string | null;
    displayName?: string | null;
    accountNumber?: string | null;
    routingNumber?: string | null;
  }>;
};

export type BannersQueryVariables = Exact<{
  input?: InputMaybe<BannersInput>;
}>;

export type BannersQuery = {
  __typename?: 'Query';
  banners: Array<{
    __typename?: 'Banner';
    id: string;
    area?: BannerArea | null;
    type?: BannerType | null;
    currency?: Symbol | null;
    irremovable?: boolean | null;
    no?: { __typename?: 'BannerContent'; title?: string | null; content?: string | null } | null;
    en?: { __typename?: 'BannerContent'; title?: string | null; content?: string | null } | null;
    da?: { __typename?: 'BannerContent'; title?: string | null; content?: string | null } | null;
  } | null>;
};

export type BuyOrdersQueryVariables = Exact<{
  symbol: Scalars['ID'];
  marketType?: InputMaybe<MarketType>;
}>;

export type BuyOrdersQuery = {
  __typename?: 'Query';
  market?: {
    __typename?: 'Market';
    symbol: string;
    lastPrice?: string | null;
    buyOrders: Array<Array<string>>;
    marketType?: MarketType | null;
  } | null;
  priceIndex?: { __typename?: 'PriceIndexPayload'; rate: string } | null;
};

export type CalendarQuestionQueryVariables = Exact<{
  day: Scalars['Int'];
}>;

export type CalendarQuestionQuery = {
  __typename?: 'Query';
  calendar?: {
    __typename?: 'Calendar';
    question?: {
      __typename?: 'CalendarQuestion';
      id: string;
      day: number;
      question: { __typename?: 'CalendarText'; da: string; en: string; no: string };
      answers: Array<{
        __typename?: 'CalendarAnswer';
        correct?: boolean | null;
        id: string;
        text: { __typename?: 'CalendarText'; da: string; en: string; no: string };
      } | null>;
      prize: {
        __typename?: 'CalendarQuestionPrize';
        currency: CryptoCurrency;
        amount: number;
        fiatCurrency: FiatCurrency;
      };
    } | null;
  } | null;
};

export type CompleteOpenBankingQueryVariables = Exact<{
  input: OpenBankingCompleteInput;
}>;

export type CompleteOpenBankingQuery = {
  __typename?: 'Query';
  openBankingCompleteTransfer: {
    __typename?: 'OpenBankingComplete';
    amount?: string | null;
    paymentId?: string | null;
  };
};

export type ConsentQueryVariables = Exact<{ [key: string]: never }>;

export type ConsentQuery = {
  __typename?: 'Query';
  gdprConsent: { __typename?: 'GDPRPayload'; v3: boolean; v4: boolean; profile: boolean };
};

export type ContactsQueryVariables = Exact<{
  currency?: InputMaybe<CryptoCurrency>;
}>;

export type ContactsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'Profile';
    id: string;
    contacts?: Array<{
      __typename?: 'Contact';
      name: string;
      address: string;
      currency: CryptoCurrency;
      tag?: string | null;
    }> | null;
  } | null;
};

export type CryptocurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CryptocurrenciesQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'AccountEntity';
    cryptoCurrencies?: Array<{
      __typename?: 'CryptoCurrencyPayload';
      id: CryptoCurrency;
      name: string;
      hasTag?: boolean | null;
    }> | null;
  };
};

export type CryptoWalletAddressQueryVariables = Exact<{
  symbol: Symbol;
  skip?: InputMaybe<Scalars['Boolean']>;
}>;

export type CryptoWalletAddressQuery = {
  __typename?: 'Query';
  currency?: {
    __typename?: 'Currency';
    symbol: Symbol;
    hasTag?: boolean | null;
    walletAddress?: {
      __typename?: 'WalletPayload';
      address: string;
      destinationTag?: string | null;
    } | null;
  } | null;
};

export type CurrencyQueryVariables = Exact<{
  symbol: Symbol;
}>;

export type CurrencyQuery = {
  __typename?: 'Query';
  currency?: {
    __typename?: 'Currency';
    symbol: Symbol;
    name: string;
    hasTag?: boolean | null;
    enabled?: {
      __typename?: 'CurrencyEnabled';
      withdraw?: boolean | null;
      deposit?: boolean | null;
    } | null;
  } | null;
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'Profile';
    id: string;
    address?: string | null;
    city?: string | null;
    country?: string | null;
    email: string;
    type: string;
    tag: string;
    uuid?: string | null;
    postalArea?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    twoFactor: boolean;
    nationality?: string | null;
    securityLevel: number;
    hasVippsLinked: boolean;
    features: Array<string>;
    dateOfBirth?: string | null;
    transactionFee?: string | null;
    affiliateCode?: string | null;
    onboardingStatus?: OnboardingStatusEnum | null;
    hasVerifiedEmail: boolean;
    mustVerifyEmail: boolean;
    createdAt?: string | null;
    actions?: Array<UserNeededAction | null> | null;
    corporate: { __typename?: 'Corporate'; name?: string | null };
    fiatMarket: { __typename?: 'FiatMarket'; id?: string | null; symbol?: FiatCurrency | null };
  } | null;
};

export type DepositAccountQueryVariables = Exact<{
  type: DepositBankAccountType;
}>;

export type DepositAccountQuery = {
  __typename?: 'Query';
  deposit: {
    __typename?: 'Deposit';
    bankAccount: {
      __typename?: 'DepositBankAccount';
      accountNumber: string;
      bankName: string;
      country: string;
      currency: FiatCurrency;
      userIdentification: string;
      userIdentificationType: DepositIdentificationType;
    };
  };
};

export type DonationQueryVariables = Exact<{
  organization: Scalars['String'];
}>;

export type DonationQuery = {
  __typename?: 'Query';
  donation?: {
    __typename?: 'Donation';
    name: string;
    short_name: string;
    start?: string | null;
    end?: string | null;
    active: boolean;
    currencies?: Array<{
      __typename?: 'DonationBalance_v2';
      currency: Symbol;
      amount: string;
      fiat?: { __typename?: 'FiatBalance'; currency: Symbol; value: string } | null;
    } | null> | null;
    total_donation?: {
      __typename?: 'DonationTotal';
      currency: Symbol;
      value: string;
      users: string;
    } | null;
  } | null;
};

export type DonationsQueryVariables = Exact<{ [key: string]: never }>;

export type DonationsQuery = {
  __typename?: 'Query';
  donations?: Array<{
    __typename?: 'Donation';
    name: string;
    short_name: string;
    start?: string | null;
    end?: string | null;
    active: boolean;
    currencies?: Array<{
      __typename?: 'DonationBalance_v2';
      currency: Symbol;
      amount: string;
      fiat?: { __typename?: 'FiatBalance'; currency: Symbol; value: string } | null;
    } | null> | null;
    total_donation?: {
      __typename?: 'DonationTotal';
      currency: Symbol;
      value: string;
      users: string;
    } | null;
  } | null> | null;
};

export type EstimatedUnstakeTimeQueryVariables = Exact<{
  currency: CryptoCurrency;
  amount: Scalars['String'];
}>;

export type EstimatedUnstakeTimeQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    estimatedUnstakeTime?: { __typename?: 'UnstakeTime'; days: number } | null;
  };
};

export type CustomerIoUserAttributesQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerIoUserAttributesQuery = {
  __typename?: 'Query';
  customerIoAttributes?: {
    __typename?: 'CustomerIoUserAttributes';
    customer: {
      __typename?: 'CustomerIoUser';
      attributes: {
        __typename?: 'CustomerAttributes';
        pushNewsletter?: boolean | null;
        pushProductsAndCryptos?: boolean | null;
        pushOtherMarketing?: boolean | null;
        pushChristmasCalendar?: boolean | null;
        pushCryptoNews?: boolean | null;
        pushCryptoMarket?: boolean | null;
      };
    };
  } | null;
};

export type PushPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type PushPreferencesQuery = {
  __typename?: 'Query';
  getPushPreferences?: {
    __typename?: 'PushNotificationPreference';
    firiFordelPushCommunication: boolean;
    firiFordelEmailCommunication: boolean;
    accountActivityPushCommunication: boolean;
    accountActivityEmailCommunication: boolean;
  } | null;
};

export type InitiateOpenBankingSessionQueryVariables = Exact<{
  input: OpenBankingSessionInput;
}>;

export type InitiateOpenBankingSessionQuery = {
  __typename?: 'Query';
  openBankingInitiateSession: {
    __typename?: 'OpenBankingSession';
    sessionID: string;
    deviceId: string;
    url: string;
  };
};

export type IntercomQueryVariables = Exact<{ [key: string]: never }>;

export type IntercomQuery = {
  __typename?: 'Query';
  intercom?: {
    __typename?: 'IntercomPayload';
    app_id: string;
    first_name: string;
    last_name: string;
    created_at: number;
    email: string;
    user_hash: string;
  } | null;
};

export type KycFormQueryVariables = Exact<{
  input: KycFormInput;
}>;

export type KycFormQuery = {
  __typename?: 'Query';
  kycForm?: {
    __typename?: 'KycFormResponse';
    id: number;
    title: string;
    prologue?: string | null;
    hasUpload?: boolean | null;
    completedOn?: { __typename?: 'SentOn'; seconds?: number | null; nanos?: number | null } | null;
    questions: Array<{
      __typename?: 'KycQuestion';
      id: number;
      order: number;
      isFreeText: boolean;
      answered: Array<string | null>;
      translationKey: string;
      title: string;
      required: boolean;
      answers: Array<{
        __typename?: 'KycAnswer';
        id?: number | null;
        answerText?: string | null;
        translationKey?: string | null;
        subsequentquestionAnswerId?: number | null;
      }>;
    }>;
  } | null;
};

export type PreviousTranslationQueryVariables = Exact<{
  input: PreviousTranslationInput;
}>;

export type PreviousTranslationQuery = {
  __typename?: 'Query';
  previousTranslation?: {
    __typename?: 'TranslationPayload';
    originalText: string;
    proposedText: string;
    author: string;
    timestamp: string;
  } | null;
};

export type LightningInvoiceQueryVariables = Exact<{
  invoice: Scalars['String'];
}>;

export type LightningInvoiceQuery = {
  __typename?: 'Query';
  lightningDeposit: { __typename?: 'DepositLightning'; invoice: string; state: string };
};

export type LightningWithdrawsQueryVariables = Exact<{ [key: string]: never }>;

export type LightningWithdrawsQuery = {
  __typename?: 'Query';
  withdrawsLightning: Array<{
    __typename?: 'WithdrawLightning';
    invoice?: string | null;
    state?: string | null;
  }>;
};

export type MarketQueryVariables = Exact<{
  symbol: Scalars['ID'];
}>;

export type MarketQuery = {
  __typename?: 'Query';
  market?: {
    __typename?: 'Market';
    symbol: string;
    lastPrice?: string | null;
    change?: string | null;
    bidPriceHighest?: string | null;
    askPriceLowest?: string | null;
    volume?: string | null;
    marketType?: MarketType | null;
    baseCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
    quoteCurrency?: { __typename?: 'Currency'; symbol: Symbol } | null;
    priceHistory: {
      __typename?: 'MarketPriceHistory';
      change24h?: number | null;
      data: Array<{ __typename?: 'MarketPriceHistoryTick'; price: string } | null>;
    };
    enabled?: { __typename?: 'MarketsEnabled'; buy?: boolean | null; sell?: boolean | null } | null;
  } | null;
};

export type MarketAdvancedQueryVariables = Exact<{
  symbol: Scalars['ID'];
  marketType?: InputMaybe<MarketType>;
}>;

export type MarketAdvancedQuery = {
  __typename?: 'Query';
  market?: {
    __typename?: 'Market';
    symbol: string;
    lastPrice?: string | null;
    change?: string | null;
    bidPriceHighest?: string | null;
    askPriceLowest?: string | null;
    volume?: string | null;
    marketType?: MarketType | null;
    baseCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
    quoteCurrency?: { __typename?: 'Currency'; symbol: Symbol } | null;
  } | null;
};

export type MarketBarQueryVariables = Exact<{
  market: Scalars['ID'];
  barInput: MarketInput;
  marketType?: InputMaybe<MarketType>;
}>;

export type MarketBarQuery = {
  __typename?: 'Query';
  market?: {
    __typename?: 'Market';
    symbol: string;
    lastPrice?: string | null;
    change?: string | null;
    baseCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
    quoteCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
    bars?: Array<{
      __typename?: 'Bar';
      date: string;
      close?: string | null;
      high?: string | null;
      low?: string | null;
      open?: string | null;
      volume?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type MarketHistoryQueryVariables = Exact<{
  symbol: Scalars['ID'];
}>;

export type MarketHistoryQuery = {
  __typename?: 'Query';
  market?: {
    __typename?: 'Market';
    symbol: string;
    history?: Array<{
      __typename?: 'MarketTrade';
      createdAt: string;
      amount: string;
      type: string;
      price: string;
    } | null> | null;
  } | null;
};

export type MarketOrdersQueryVariables = Exact<{
  symbol?: InputMaybe<Symbol>;
}>;

export type MarketOrdersQuery = {
  __typename?: 'Query';
  orders?: Array<{
    __typename?: 'Order';
    id: string;
    amount: string;
    price: string;
    remaining: string;
    createdAt: string;
    type: OrderType;
    market: { __typename?: 'Market'; symbol: string };
  }> | null;
};

export type MarketFragment = {
  __typename?: 'Market';
  symbol: string;
  lastPrice?: string | null;
  change?: string | null;
  volume?: string | null;
  marketType?: MarketType | null;
  hasAdvanced?: boolean | null;
  baseCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
  quoteCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
};

export type MarketsQueryQueryVariables = Exact<{
  includeOtc?: InputMaybe<Scalars['Boolean']>;
  period: PnlPeriod;
}>;

export type MarketsQueryQuery = {
  __typename?: 'Query';
  markets: Array<{
    __typename?: 'Market';
    symbol: string;
    lastPrice?: string | null;
    change?: string | null;
    volume?: string | null;
    marketType?: MarketType | null;
    hasAdvanced?: boolean | null;
    priceHistory: {
      __typename?: 'MarketPriceHistory';
      change?: number | null;
      data: Array<{ __typename?: 'MarketPriceHistoryTick'; price: string; date: string } | null>;
    };
    baseCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
    quoteCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
  }>;
};

export type MetadataQueryVariables = Exact<{ [key: string]: never }>;

export type MetadataQuery = {
  __typename?: 'Query';
  metadata: {
    __typename?: 'Metadata';
    hasTraded?: boolean | null;
    hasDeposited?: boolean | null;
    hasTransaction?: boolean | null;
    hasSavingsAccount?: boolean | null;
    hasStakingAccount?: boolean | null;
    hasSavingsAgreement?: boolean | null;
    memeConsent?: boolean | null;
    fraudAcknowledge?: boolean | null;
    readArticles?: Array<string> | null;
    favorites?: Array<CryptoCurrency> | null;
  };
};

export type OpenBankingBanksQueryVariables = Exact<{
  country: OpenBankingCountryCode;
}>;

export type OpenBankingBanksQuery = {
  __typename?: 'Query';
  payment: {
    __typename?: 'Payment';
    openbankingBanks: Array<{
      __typename?: 'BankOpenBanking';
      displayName: string;
      bankOfficialName?: string | null;
      providers: Array<{
        __typename?: 'OpenBankingProvider';
        id: string;
        name: OpenBankingProviderName;
        limitedTo?: number | null;
        personalIdentificationRequired?: boolean | null;
      }>;
    }>;
  };
};

export type OpenBankingPaymentQueryVariables = Exact<{
  input: OpenBankingTransferInput;
}>;

export type OpenBankingPaymentQuery = {
  __typename?: 'Query';
  openBankingDomesticTransfer: {
    __typename?: 'OpenBankingTransfer';
    url?: string | null;
    status?: OpenBankingPaymentConsent | null;
    errorMsg?: string | null;
    paymentId?: string | null;
  };
};

export type OpenBanksQueryVariables = Exact<{ [key: string]: never }>;

export type OpenBanksQuery = {
  __typename?: 'Query';
  openBankingBanks: Array<{
    __typename?: 'OpenBankingBank';
    countryCode: OpenBankingCountryCode;
    bankingGroupName: string;
    personalIdentificationRequired: boolean;
    id: string;
    bankDisplayName: string;
    supportedServices: Array<string>;
    bic: string;
    bankOfficialName: string;
    status: string;
  }>;
};

export type OtcPricesQueryVariables = Exact<{
  base: CryptoCurrency;
  quote: FiatCurrency;
}>;

export type OtcPricesQuery = {
  __typename?: 'Query';
  otc: {
    __typename?: 'Otc';
    prices?: {
      __typename?: 'OtcPrices';
      ask: string;
      bid: string;
      maxBidVolume: string;
      maxAskVolume: string;
    } | null;
  };
};

export type PnlBalanceQueryVariables = Exact<{
  input: PnlInput;
}>;

export type PnlBalanceQuery = {
  __typename?: 'Query';
  pnl?: {
    __typename?: 'PNL';
    balance?: {
      __typename?: 'PNLBalance';
      fiatCurrency: FiatCurrency;
      total: { __typename?: 'YieldTotal'; fiatAmount: string; percent: string };
      currencies?: Array<{
        __typename?: 'PNLBalanceCurrency';
        cryptoCurrency: CryptoCurrency;
        fiatAmount: string;
        percent: string;
      }> | null;
    } | null;
  } | null;
};

export type PnlYieldQueryVariables = Exact<{
  input: PnlInput;
}>;

export type PnlYieldQuery = {
  __typename?: 'Query';
  pnl?: {
    __typename?: 'PNL';
    yield?: {
      __typename?: 'YieldData';
      data?: Array<{
        __typename?: 'YieldDataPoint';
        date: string;
        total: {
          __typename?: 'YieldTotal';
          totalBalanceWithFiat: string;
          fiatBalance: string;
          fiatAmount: string;
          percent: string;
        };
        currencies?: Array<{
          __typename?: 'YieldCurrency';
          cryptoCurrency: CryptoCurrency;
          fiatBalance: string;
          fiatAmount: string;
          percent: string;
        }> | null;
      }> | null;
    } | null;
  } | null;
};

export type PaymentDirectOrderStatusQueryVariables = Exact<{
  merchantReference: Scalars['String'];
}>;

export type PaymentDirectOrderStatusQuery = {
  __typename?: 'Query';
  payment: {
    __typename?: 'Payment';
    paymentDirectOrderStatus: {
      __typename?: 'PaymentDirectOrderStatus';
      paymentStatus: AdyenPaymentStatus;
      depositFee: string;
      order?: {
        __typename?: 'MarketOrderPayload';
        fee: string;
        exchangeRate: string;
        assetMatched: string;
        fiatMatched: string;
        status?: OrderStatus | null;
      } | null;
    };
  };
};

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodsQuery = {
  __typename?: 'Query';
  payment: {
    __typename?: 'Payment';
    paymentMethods?: Array<{
      __typename?: 'PaymentMethod';
      type: PaymentMethodType;
      hasFee: boolean;
      instantSettlement: boolean;
      fastest: boolean;
    }> | null;
  };
};

export type PaymentStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PaymentStatusQuery = {
  __typename?: 'Query';
  payment: { __typename?: 'Payment'; paymentStatus: DepositStatus };
};

export type PriceHistoryQueryVariables = Exact<{
  market: Scalars['ID'];
  period: PnlPeriod;
}>;

export type PriceHistoryQuery = {
  __typename?: 'Query';
  market?: {
    __typename?: 'Market';
    symbol: string;
    baseCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
    priceHistory: {
      __typename?: 'MarketPriceHistory';
      change?: number | null;
      data: Array<{ __typename?: 'MarketPriceHistoryTick'; date: string; price: string } | null>;
    };
  } | null;
};

export type PriceIndexQueryVariables = Exact<{
  symbol: Scalars['ID'];
}>;

export type PriceIndexQuery = {
  __typename?: 'Query';
  priceIndex?: { __typename?: 'PriceIndexPayload'; rate: string } | null;
};

export type PriceStatsQueryVariables = Exact<{
  currency: CryptoCurrency;
  accountGroupId?: InputMaybe<Scalars['String']>;
}>;

export type PriceStatsQuery = {
  __typename?: 'Query';
  pnl?: {
    __typename?: 'PNL';
    priceStats?: {
      __typename?: 'PriceStats';
      id: string;
      maxPrice?: string | null;
      minPrice?: string | null;
      avgPrice?: string | null;
    } | null;
  } | null;
};

export type ProfilePictureQueryVariables = Exact<{ [key: string]: never }>;

export type ProfilePictureQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'Profile';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    profilePicture?: string | null;
  } | null;
};

export type ProfilePictureUploadUrlQueryVariables = Exact<{ [key: string]: never }>;

export type ProfilePictureUploadUrlQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'Profile';
    id: string;
    profilePictureUploadUrl?: string | null;
  } | null;
};

export type RecaptchaQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type RecaptchaQuery = {
  __typename?: 'Query';
  verifyCaptchaToken?: { __typename?: 'RecapthaPayload'; uuid: string } | null;
};

export type ReferralBonusQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
}>;

export type ReferralBonusQuery = {
  __typename?: 'Query';
  referralBonus?: {
    __typename?: 'ReferralBonus';
    amount?: string | null;
    payoutCurrency?: CryptoCurrency | null;
    welcomeBonus?: string | null;
    welcomeBonusCurrency?: string | null;
    code?: string | null;
  } | null;
};

export type ReferralOwnerQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type ReferralOwnerQuery = {
  __typename?: 'Query';
  referralOwner: {
    __typename?: 'AffiliateOwnerPayload';
    name?: string | null;
    validTo?: string | null;
    welcomeBonus?: string | null;
    welcomeBonusCurrency?: string | null;
  };
};

export type ReferralStatsQueryVariables = Exact<{ [key: string]: never }>;

export type ReferralStatsQuery = {
  __typename?: 'Query';
  affiliateStats?: {
    __typename?: 'AffiliateStats';
    bonus: Array<{
      __typename?: 'AffiliateStat';
      currency: Symbol;
      type: AffiliateTransaction;
      last: string;
      pending: string;
      total: string;
    }>;
    commission: Array<{
      __typename?: 'AffiliateStat';
      currency: Symbol;
      type: AffiliateTransaction;
      last: string;
      pending: string;
      total: string;
    }>;
    feebackBonus: Array<{
      __typename?: 'AffiliateStat';
      currency: Symbol;
      type: AffiliateTransaction;
      last: string;
      pending: string;
      total: string;
    }>;
  } | null;
  referralCount?: {
    __typename?: 'ReferralCount';
    last: string;
    pending: string;
    total: string;
  } | null;
};

export type ReferralsQueryVariables = Exact<{ [key: string]: never }>;

export type ReferralsQuery = {
  __typename?: 'Query';
  affiliate?: {
    __typename?: 'Affiliate';
    referrals?: Array<{
      __typename?: 'AffiliateReferral';
      code?: string | null;
      count?: {
        __typename?: 'ReferralCountTotal';
        completed?: number | null;
        pending?: number | null;
      } | null;
      payout?: Array<{
        __typename?: 'Payout';
        received?: string | null;
        pending?: string | null;
        currency?: Symbol | null;
      } | null> | null;
      referrals?: Array<{
        __typename?: 'Referral';
        id?: string | null;
        name?: string | null;
        status?: ReferralStatus | null;
        referredAt: string;
        payout?: {
          __typename?: 'ReferralPayout';
          currency?: FiatCurrency | null;
          amount?: string | null;
          payoutAmount?: string | null;
          payoutCurrency?: Symbol | null;
          paidAt?: string | null;
          creditedAt?: string | null;
        } | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type RewardsQueryVariables = Exact<{ [key: string]: never }>;

export type RewardsQuery = {
  __typename?: 'Query';
  affiliate?: {
    __typename?: 'Affiliate';
    rewards?: Array<{
      __typename?: 'AffiliateReward';
      uuid: string;
      userID: string;
      type?: RewardTypes | null;
      level?: string | null;
      description?: string | null;
      metric?: string | null;
      metricGoal?: string | null;
      metricName?: string | null;
      completed?: boolean | null;
      fiatValue?: string | null;
      amount?: string | null;
      currency?: Symbol | null;
      transactionType?: AffiliateTransaction | null;
      paidAt?: string | null;
      updatedAt?: string | null;
      completedAt?: string | null;
      fiatCurrency?: FiatCurrency | null;
    }> | null;
  } | null;
};

export type SellOrdersQueryVariables = Exact<{
  symbol: Scalars['ID'];
  marketType?: InputMaybe<MarketType>;
}>;

export type SellOrdersQuery = {
  __typename?: 'Query';
  market?: {
    __typename?: 'Market';
    symbol: string;
    lastPrice?: string | null;
    sellOrders: Array<Array<string>>;
    change?: string | null;
    marketType?: MarketType | null;
    baseCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
  } | null;
  priceIndex?: { __typename?: 'PriceIndexPayload'; rate: string } | null;
};

export type ShouldUpdateSavingsDepositQueryVariables = Exact<{ [key: string]: never }>;

export type ShouldUpdateSavingsDepositQuery = {
  __typename?: 'Query';
  shouldUpdateSavingsDeposit: boolean;
};

export type StakedAssetQueryVariables = Exact<{
  currency: CryptoCurrency;
}>;

export type StakedAssetQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    stakedCurrency?: {
      __typename?: 'StakedStakingAsset';
      currency: CryptoCurrency;
      fiatCurrency: FiatCurrency;
      name?: string | null;
      type: StakingType;
      minimumStaked?: number | null;
      lockPeriod?: number | null;
      rewardDelay?: number | null;
      epochLength?: number | null;
      apy?: number | null;
      price?: number | null;
      stats?: {
        __typename?: 'StakingAssetStats';
        active?: boolean | null;
        amount?: number | null;
        fiatValue?: number | null;
        earnings?: {
          __typename?: 'StakingAssetEarnings';
          amount?: number | null;
          fiatValue?: number | null;
          yield?: number | null;
        } | null;
      } | null;
      accounts?: Array<{
        __typename?: 'StakingAccounts';
        accountGroupId: string;
        stakedAmount: number;
      }> | null;
    } | null;
  };
};

export type StakedAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type StakedAssetsQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    stakedCurrencies?: Array<{
      __typename?: 'StakedStakingAsset';
      currency: CryptoCurrency;
      fiatCurrency: FiatCurrency;
      name?: string | null;
      type: StakingType;
      minimumStaked?: number | null;
      lockPeriod?: number | null;
      rewardDelay?: number | null;
      epochLength?: number | null;
      apy?: number | null;
      price?: number | null;
      stats?: {
        __typename?: 'StakingAssetStats';
        active?: boolean | null;
        amount?: number | null;
        fiatValue?: number | null;
        earnings?: {
          __typename?: 'StakingAssetEarnings';
          amount?: number | null;
          fiatValue?: number | null;
          yield?: number | null;
        } | null;
      } | null;
      accounts?: Array<{
        __typename?: 'StakingAccounts';
        accountGroupId: string;
        stakedAmount: number;
      }> | null;
    }> | null;
  };
};

export type StakingAssetQueryVariables = Exact<{
  currency: CryptoCurrency;
}>;

export type StakingAssetQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    currency?: {
      __typename?: 'StakingAsset';
      currency: CryptoCurrency;
      fiatCurrency: FiatCurrency;
      name?: string | null;
      type: StakingType;
      minimumStaked?: number | null;
      lockPeriod?: number | null;
      rewardDelay?: number | null;
      epochLength?: number | null;
      apy?: number | null;
      price?: number | null;
    } | null;
  };
};

export type StakingAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type StakingAssetsQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    currencies?: Array<{
      __typename?: 'StakingAsset';
      currency: CryptoCurrency;
      fiatCurrency: FiatCurrency;
      name?: string | null;
      type: StakingType;
      minimumStaked?: number | null;
      lockPeriod?: number | null;
      rewardDelay?: number | null;
      epochLength?: number | null;
      apy?: number | null;
      price?: number | null;
    }> | null;
  };
};

export type StakingAssetsAttainableQueryVariables = Exact<{ [key: string]: never }>;

export type StakingAssetsAttainableQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    notStakedCurrencies?: Array<{
      __typename?: 'StakingAsset';
      currency: CryptoCurrency;
      fiatCurrency: FiatCurrency;
      name?: string | null;
      type: StakingType;
      minimumStaked?: number | null;
      lockPeriod?: number | null;
      rewardDelay?: number | null;
      epochLength?: number | null;
      apy?: number | null;
      price?: number | null;
    }> | null;
  };
};

export type StakingAssetsAvailableQueryVariables = Exact<{ [key: string]: never }>;

export type StakingAssetsAvailableQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    notStakedCurrenciesWithHold?: Array<{
      __typename?: 'StakingAsset';
      currency: CryptoCurrency;
      fiatCurrency: FiatCurrency;
      name?: string | null;
      type: StakingType;
      minimumStaked?: number | null;
      lockPeriod?: number | null;
      rewardDelay?: number | null;
      epochLength?: number | null;
      apy?: number | null;
      price?: number | null;
    }> | null;
  };
};

export type StakingStatsQueryVariables = Exact<{ [key: string]: never }>;

export type StakingStatsQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    stats?: {
      __typename?: 'StakingStats';
      fiatValue?: number | null;
      fiatCurrency?: FiatCurrency | null;
      apy?: number | null;
    } | null;
  };
};

export type SystemStatusQueryVariables = Exact<{ [key: string]: never }>;

export type SystemStatusQuery = {
  __typename?: 'Query';
  system: {
    __typename?: 'System';
    health?: Array<{
      __typename?: 'SystemStatus';
      status: SystemStatusEnum;
      systemName: string;
    }> | null;
  };
};

export type TaxRebuildingQueryVariables = Exact<{ [key: string]: never }>;

export type TaxRebuildingQuery = {
  __typename?: 'Query';
  tax?: { __typename?: 'Tax'; rebuilding?: boolean | null } | null;
};

export type TaxReportQueryVariables = Exact<{
  year: TaxYear;
}>;

export type TaxReportQuery = {
  __typename?: 'Query';
  tax?: {
    __typename?: 'Tax';
    report?: {
      __typename?: 'TaxReport';
      shouldRebuild?: RebuildCause | null;
      year?: string | null;
      fees?: string | null;
      data?: Array<{
        __typename?: 'TaxReportData';
        type?: Symbol | null;
        fortune?: string | null;
        profit?: string | null;
        loss?: string | null;
        income?: string | null;
        amount?: string | null;
      }> | null;
      missingPrices?: Array<{
        __typename?: 'TaxTransactionMissingPrice';
        id: string;
        time: string;
        amount: string;
        currency: Symbol;
        fiatValue: string;
        processed?: TaxTransactionProcessed | null;
        accountId: string;
        income?: boolean | null;
        fee?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type TinkAccountsQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
}>;

export type TinkAccountsQuery = {
  __typename?: 'Query';
  payment: {
    __typename?: 'Payment';
    tinkAccounts?: Array<{
      __typename?: 'TinkAccount';
      currency?: FiatCurrency | null;
      balance?: number | null;
      balanceType?: string | null;
      bban?: string | null;
      iban?: string | null;
      accountName?: string | null;
      accountType?: string | null;
      id?: string | null;
    }> | null;
  };
};

export type TopMoversQueryVariables = Exact<{
  includeOtc?: InputMaybe<Scalars['Boolean']>;
}>;

export type TopMoversQuery = {
  __typename?: 'Query';
  markets: Array<{
    __typename?: 'Market';
    symbol: string;
    lastPrice?: string | null;
    change?: string | null;
    baseCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
    quoteCurrency?: { __typename?: 'Currency'; symbol: Symbol; name: string } | null;
  }>;
};

export type TransactionQueryVariables = Exact<{
  entities: Array<SearchEntity> | SearchEntity;
  count: Scalars['Int'];
  nextPage?: InputMaybe<Array<SearchInputNextPage> | SearchInputNextPage>;
  filter?: InputMaybe<SearchInputFilter>;
  accountGroupId?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TransactionQuery = {
  __typename?: 'Query';
  search?: {
    __typename?: 'Search';
    hasMoreData: boolean;
    nextPage: Array<{ __typename?: 'SearchNextPage'; entity: string; afterId?: string | null }>;
    results: Array<
      | {
          __typename?: 'Trade';
          id: string;
          tradeType: TradeType;
          createdAt: string;
          price?: string | null;
          priceCurrency?: Symbol | null;
          buyAmount?: string | null;
          buyCurrency?: Symbol | null;
          sellAmount?: string | null;
          sellCurrency?: Symbol | null;
          feeAmount?: string | null;
          feeCurrency?: Symbol | null;
          accountGroupId: number;
          tradeDetails?: Array<{
            __typename?: 'Trade';
            id: string;
            tradeType: TradeType;
            createdAt: string;
            price?: string | null;
            priceCurrency?: Symbol | null;
            buyAmount?: string | null;
            buyCurrency?: Symbol | null;
            sellAmount?: string | null;
            sellCurrency?: Symbol | null;
            feeAmount?: string | null;
            feeCurrency?: Symbol | null;
            accountGroupId: number;
            depositDetails?: {
              __typename?: 'TradeDepositDetails';
              depositType: string;
              amount: string;
              fee: string;
              totalAmount: string;
            } | null;
          }> | null;
          depositDetails?: {
            __typename?: 'TradeDepositDetails';
            depositType: string;
            amount: string;
            fee: string;
            totalAmount: string;
          } | null;
        }
      | {
          __typename?: 'TransactionMisc';
          id: string;
          accountGroupId: number;
          createdAt: string;
          amount: string;
          type: TransactionsMiscTypes;
          currency: Symbol;
          fromAccountGroupId?: number | null;
          toAccountGroupId?: number | null;
          fiatValue?: string | null;
        }
      | {
          __typename?: 'TransferDeposit';
          id: string;
          amount: string;
          currency: Symbol;
          createdAt: string;
          address?: string | null;
          status: DepositState;
          confirmations: number;
          required: number;
          isFiat: boolean;
          txid?: string | null;
          depositType: DepositType;
          accountGroupId: number;
          transactionId?: string | null;
          fee: string;
          fiatValue?: string | null;
        }
      | {
          __typename?: 'TransferWithdraw';
          id: string;
          amount: string;
          currency: Symbol;
          createdAt: string;
          state?: WithdrawState | null;
          destination?: string | null;
          txid?: string | null;
          feeAmount?: string | null;
          feeCurrency?: Symbol | null;
          isFiat: boolean;
          fiatValue?: string | null;
          accountGroupId: number;
        }
    >;
  } | null;
};

export type UnstakingRequestsQueryVariables = Exact<{
  currency?: InputMaybe<CryptoCurrency>;
  status?: InputMaybe<Array<UnstakingRequestStatus> | UnstakingRequestStatus>;
}>;

export type UnstakingRequestsQuery = {
  __typename?: 'Query';
  staking: {
    __typename?: 'Staking';
    unstakingRequests?: Array<{
      __typename?: 'UnstakingRequests';
      id?: string | null;
      status?: UnstakingRequestStatus | null;
      currency?: CryptoCurrency | null;
      createdAt?: string | null;
      completedAt?: string | null;
      amount?: number | null;
      updatedAt?: string | null;
    }> | null;
  };
};

export type ValidateAddressQueryVariables = Exact<{
  address: Scalars['String'];
  currency?: InputMaybe<Symbol>;
  unlistedCurrency?: InputMaybe<Scalars['String']>;
}>;

export type ValidateAddressQuery = {
  __typename?: 'Query';
  transfers?: {
    __typename?: 'Transfer';
    validateAddress: {
      __typename?: 'AddressValidity';
      valid?: boolean | null;
      error?: AddressValidityError | null;
    };
  } | null;
};

export type VideoIdResultQueryVariables = Exact<{
  input: IdVerificationResultInput;
}>;

export type VideoIdResultQuery = {
  __typename?: 'Query';
  idVerificationResult: {
    __typename?: 'IdVerificationResultPayload';
    status: IdVerificationStatus;
  };
};

export type VideoIdVerificationUrlQueryVariables = Exact<{
  input: IdVerificationInput;
}>;

export type VideoIdVerificationUrlQuery = {
  __typename?: 'Query';
  idVerificationUrl: { __typename?: 'IdVerificationPayload'; url?: string | null };
};

export type VoucherQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type VoucherQuery = {
  __typename?: 'Query';
  voucher?: {
    __typename?: 'VoucherEntity';
    voucher?: {
      __typename?: 'Voucher';
      id: string;
      amount: string;
      currency: CryptoCurrency;
      createdAt: string;
      from: string;
      to: string;
      voucherType: VoucherType;
    } | null;
  } | null;
};

export type WithdrawLimitQueryVariables = Exact<{
  currency?: InputMaybe<Symbol>;
}>;

export type WithdrawLimitQuery = {
  __typename?: 'Query';
  withdraw: {
    __typename?: 'WithdrawEntity';
    withdrawLimit: {
      __typename?: 'WithdrawUsageAndLimit';
      currency: Symbol;
      usage: number;
      remaining: number;
      index: number;
      limit: number;
      maxLimit: number;
      locked: boolean;
      lockedAmount: number;
      fee: number;
      minLimit: { __typename?: 'WithdrawMinimumLimit'; source: number; limit: number };
    };
  };
};
