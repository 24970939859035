import { getCookieByName } from './getCookieByName';

export const APPSFLYER_TEMPLATE_LINK = 'https://firi.onelink.me/ZT6F';

export const getAppDownloadUrl = (options: {
  platform?: 'ios' | 'android';
  deviceId?: string;
  locale?: string;
}) => {
  if (options.deviceId && checkForAnyCookieAttributions()) {
    return getAppsflyerDownloadLink(options.deviceId);
  }
  if (options.platform === 'ios') {
    return getAppStoreDownload(options.locale);
  }
  if (options.platform === 'android') {
    return getPlayStoreDownload(options.locale);
  }
  return 'https://firi.com/app-link';
};

const getAppStoreDownload = (locale: string | undefined) => {
  if (locale === 'no') {
    return 'https://apps.apple.com/no/app/miraiex/id1542108249';
  }
  if (locale === 'dk') {
    return 'https://apps.apple.com/dk/app/miraiex/id1542108249';
  }
  return 'https://apps.apple.com/no/app/miraiex/id1542108249';
};

const getPlayStoreDownload = (locale: string | undefined) => {
  if (locale === 'no') {
    return 'https://play.google.com/store/apps/details?id=com.mirai.miraiex';
  }
  if (locale === 'dk') {
    return 'https://play.google.com/store/apps/details?id=com.mirai.miraiex&hl=da&gl=DK';
  }
  return 'https://play.google.com/store/apps/details?id=com.mirai.miraiex';
};

/**
 * It leverages cookie so only works in browser
 * Important that <a> doesn't use target=_blank, since the weakens the attribution tracking
 */
const getAppsflyerDownloadLink = (deviceId?: string) => {
  const { utmSource, utmMedium, utmCampaign, utmContent, referrer, atgd } = trackingAttributions();
  if (deviceId) {
    const appsflyerLink = `${APPSFLYER_TEMPLATE_LINK}?deep_link_value=web&deep_link_sub5=${deviceId}`;

    // This link is smart enough to auto redirect to correct country app store
    return appendDeepLinkAttributes(appsflyerLink, {
      utmSource,
      utmMedium,
      utmCampaign,
      utmContent,
      referrer,
      atgd,
    });
  }
  return appendDeepLinkAttributes(APPSFLYER_TEMPLATE_LINK, {
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    referrer,
    atgd,
  });
};

type AppsFlyerAttributes = {
  utmSource?: string | string[] | null;
  utmMedium?: string | string[] | null;
  utmCampaign?: string | string[] | null;
  utmContent?: string | string[] | null;
  referrer?: string | string[] | null;
  atgd?: string | string[] | null;
};

export const appendDeepLinkAttributes = (link: string, attributes: AppsFlyerAttributes) => {
  if (attributes.utmSource && typeof attributes.utmSource === 'string') {
    link += `&deep_link_sub1=${attributes.utmSource}`;
  }
  if (attributes.utmMedium && typeof attributes.utmMedium === 'string') {
    link += `&deep_link_sub2=${attributes.utmMedium}`;
  }
  if (attributes.utmCampaign && typeof attributes.utmCampaign === 'string') {
    link += `&deep_link_sub3=${attributes.utmCampaign}`;
  }
  if (attributes.utmContent && typeof attributes.utmContent === 'string') {
    link += `&deep_link_sub4=${attributes.utmContent}`;
  }
  if (attributes.referrer && typeof attributes.referrer === 'string') {
    link += `&deep_link_sub7=${attributes.referrer}`;
  }
  if (attributes.atgd && typeof attributes.atgd === 'string') {
    link += `&deep_link_sub6=${attributes.atgd}`;
  }
  return link;
};

export const trackingAttributions = () => {
  return {
    referrer: getCookieByName('referrer'),
    utmSource: getCookieByName('utm_source'),
    utmMedium: getCookieByName('utm_medium'),
    utmCampaign: getCookieByName('utm_campaign'),
    utmContent: getCookieByName('utm_content'),
    atgd: getCookieByName('at_gd'),
  };
};

const checkForAnyCookieAttributions = () => {
  return (
    Boolean(getCookieByName('referrer')) ||
    Boolean(getCookieByName('utm_source')) ||
    Boolean(getCookieByName('utm_medium')) ||
    Boolean(getCookieByName('utm_campaign')) ||
    Boolean(getCookieByName('utm_content'))
  );
};
